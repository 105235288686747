export interface UserInterface {
	id: string,
	active: boolean,
	verified: boolean,
	email: string,
	name: string,
}

export class UserClass implements UserInterface {
	id = '';
	active = false;
	verified = false;
	email = '';
	name = '';
}
