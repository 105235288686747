<template>
	<footer class="layout-footer">
		<div class="container py-5">
			<div class="row gap-4 gap-lg-0">
				<div
						v-for="(section, index) in this.footerData"
						class="col-12 col-lg mb-lg-0"
				>
					<h4 v-if="section.show_title" class="layout-footer__section-header mb-3">{{ section.title }}</h4>
					<div class="layout-footer__section-item"
						 v-html="section.content"
					/>
				</div>
			</div>
		</div>

		<div class="layout-footer-bottom" v-if="secondaryFooter?.type && secondaryFooter?.type !== 'disabled'">
			<div class="container" v-if="secondaryFooter?.type === 'structure'">
				<div class="d-flex align-items-start align-items-sm-center flex-lg-row flex-column">
					<ul>
						<li v-for="link in this.links"><a :href="link.link" target="_blank">{{ link.title }}</a></li>
					</ul>
					<div class="layout-footer-bottom__icons">
						<a v-for="icon in this.icons" :href="icon.link" target="_blank"><i :class="icon.icon"></i></a>
					</div>
				</div>
			</div>
			<div class="container" v-if="secondaryFooter?.type === 'text'" v-html="secondaryFooter.text"></div>
		</div>
	</footer>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {LayoutCssVariables} from "../../Utils/LayoutCssVariables";
import axios from "axios";
import Sweetalert2 from "sweetalert2";
import {LayoutFooterSectionItemInterface} from "./Interfaces/LayoutFooterSectionInterface";

export default defineComponent({
	name: "LayoutFooter",
	data() {
		return {
			footerData: [] as LayoutFooterSectionItemInterface[],
			secondaryFooter: [],
		}
	},
	mounted() {
		this.getFooterData();
	},
	computed: {
		primaryBgColor(): string {
			return `var(--${LayoutCssVariables.footer.primaryBgColor})`;
		},
		sectionHeaderTextColor(): string {
			return `var(--${LayoutCssVariables.footer.sectionHeaderTextColor})`;
		},
		sectionContentTextColor(): string {
			return `var(--${LayoutCssVariables.footer.sectionContentTextColor})`;
		},
		secondaryBgColor(): string {
			return `var(--${LayoutCssVariables.footer.secondaryBgColor})`;
		},
		secondaryTextColor(): string {
			return `var(--${LayoutCssVariables.footer.secondaryTextColor})`;
		},
		links() {
			return this.secondaryFooter?.items.filter((item: any) => item.icon.length === 0);
		},
		icons() {
			return this.secondaryFooter?.items.filter((item: any) => item.icon.length > 0);
		}
	},
	methods: {
		getFooterData(): void {
			axios.get('/api/footer').then((response: any) => {
				if (response.status === 200 && response.data.success === true) {
					this.footerData = response.data.items;
					if (response.data.secondary_footer.length > 0) {
						this.secondaryFooter = JSON.parse(response.data.secondary_footer);
					}
				}
			}).catch((error: any) => {
				console.log(error);
				Sweetalert2.fire(this.translate('ops-error-title'), this.translate('ops-error-text'), 'error');
			});
		},
	},
});
</script>

<style scoped>
.layout-footer {
	background-color: v-bind(primaryBgColor);
}

.layout-footer__section-header {
	color: v-bind(sectionHeaderTextColor);
	font-size: 16px;
}

.layout-footer__section-item {
	color: v-bind(sectionContentTextColor);
	font-family: "Poppins", sans-serif;
	font-size: 14px;
}

.layout-footer__section-item :deep(*) {
	color: v-bind(sectionContentTextColor);
}

.layout-secondary-footer {
	background-color: v-bind(secondaryBgColor);
	color: v-bind(secondaryTextColor);
}
</style>
