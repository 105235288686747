<template>
	<p class="my-5"> {{ this.$root?.translate('advert-application-info-1') }}</p>
	<p>{{ this.$root?.translate('you-are-in-user') }}</p>

	<p class="my-2">{{ this.$root?.translate('email') }}: <b>{{ this.currentUser.email }}</b></p>

	<p class="my-5">
		<a href="#" @click="logout()">{{ this.$root?.translate('im-logged-out') }}</a> {{ this.$root?.translate('im-logged-out-info') }}
	</p>

	<div class="d-flex flex-column-reverse flex-md-row align-items-center justify-content-md-between">
		<button-unit
				:options="{
						text: this.$root?.translate('cancel'),
						shape: ButtonUnitShape.ROUNDED,
						textColor: this.primaryColorVar,
						bgColor: '',
						class: 'lg bordered-lg login-registration__secondary-btn',
						disabled: this.isLoading,
					}"
				@click-on-btn="this.$emit('closeModal')"
		/>
		<button-unit
				:options="{
						text: this.$root?.translate('advert-application'),
						type: 'submit',
						shape: ButtonUnitShape.ROUNDED,
						bgColor: this.primaryColorVar,
						textColor: this.buttonTextColor,
						class: 'lg mb-4 mb-md-0 login-registration__primary-btn',
						disabled: this.isLoading,
						showLoader: this.isLoading,
					}"
				@click-on-btn="this.goApply()"
		/>
	</div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import ProfilePageMixin from "../../../Mixins/ProfilePageMixin";
import InputUnit from "../../Units/InputUnit.vue";
import ButtonUnit from "../../Units/ButtonUnit.vue";
import {RegistrationModelInterface} from "../../../Interfaces/RegistrationModelInterface";
import {UserInterface} from "../../../Interfaces/UserInterface";
import {useAuthStore} from "../../../Stores/Auth/AuthStore";

export default defineComponent({
	name: "AdvertApplyLoggedIn",
	components: {ButtonUnit, InputUnit},
	mixins: [ProfilePageMixin],
	setup() {
		return {
			authStore: useAuthStore()
		}
	},
	props: {
		isLoading: {
			type: Boolean,
			required: true,
		},
	},
	emits: ['closeModal', 'applyAdvert'],
	computed: {
		currentUser(): UserInterface {
			return this.authStore.currentUser;
		}
	},
	methods: {
		goApply(): void {
			this.$emit('applyAdvert');
		},
		logout(): void {
			this.authStore.logout();
		}
	}
});
</script>

<style scoped>
</style>
