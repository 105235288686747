export interface RegistrationModelInterface {
	first_name: string,
	last_name: string,
	email: string,
	telephone: string,
	date_of_birth: string,
	password: string,
	policy: boolean,
	newsletter: boolean,
	is_register: boolean,
	code: string,
	advert_id?: number
}

export class RegistrationModelClass implements RegistrationModelInterface {
	first_name = '';
	last_name = '';
	email = '';
	telephone = '';
	date_of_birth = '';
	password = '';
	policy = false;
	newsletter = false;
	is_register = false;
	code = '';
}
