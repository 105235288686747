import {defineComponent} from "vue";

export default defineComponent({
	data() {
		return {
			months: [
				this.$root?.translate('january'),
				this.$root?.translate('february'),
				this.$root?.translate('march'),
				this.$root?.translate('april'),
				this.$root?.translate('may'),
				this.$root?.translate('jun'),
				this.$root?.translate('july'),
				this.$root?.translate('aug'),
				this.$root?.translate('september'),
				this.$root?.translate('october'),
				this.$root?.translate('november'),
				this.$root?.translate('december'),
			],
		}
	},
});
