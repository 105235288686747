<template>
	<p class="mb-3">{{ translate('enter-new-password') }}</p>
	<p class="my-3">{{ translate('look-at-your-email-for-security-code') }}</p>

	<form @submit.prevent="lostPassword()">
		<div class="row">
			<div class="col-12">
				<input-unit
					v-model="email"
					:options="{
						slug: 'lost-password-email',
						name: translate('email'),
						placeholder: translate('email-placeholder'),
						required: true,
						disabled: this.modelValue.email ? true : false,
					}"
				/>
			</div>
			<div class="col-12 my-4">
				<email-verified-input
					v-model="code"
					:email="email"
					:options="{
						slug: 'lost-password-code',
						name: this.$root?.translate('code'),
						standardText: false,
						placeholder: this.$root?.translate('please-enter-code'),
						minlength: 6,
						required: true
					}"
				/>
			</div>
			<div class="col-12">
				<password-unit
						v-model="new_password"
						:options="{
							slug: 'lost-password-new-password',
							name: translate('new-password'),
							placeholder: translate('min-8-char'),
							minlength: 8,
							required: true,
						}"
				/>
			</div>
		</div>

		<div class="d-flex flex-column-reverse flex-md-row align-items-center justify-content-md-between mt-5">
			<button-unit
					:options="{
						text: translate('back-tp-login'),
						shape: ButtonUnitShape.ROUNDED,
						textColor: primaryColorVar,
						bgColor: '',
						class: 'lg bordered-lg login-registration__secondary-btn',
						disabled: isLoading,
					}"
					@click-on-btn="goBack()"
			/>
			<button-unit
					:options="{
						text: translate('change-my-password'),
						type: 'submit',
						shape: ButtonUnitShape.ROUNDED,
						bgColor: primaryColorVar,
						textColor: buttonTextColor,
						class: 'lg mb-4 mb-md-0 login-registration__primary-btn',
						disabled: isLoading,
						showLoader: isLoading,
					}"
					@click-on-btn="lostPassword()"
			/>
		</div>
	</form>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {useAuthStore} from "../../../Stores/Auth/AuthStore";
import {RegistrationModelInterface} from "../../../Interfaces/RegistrationModelInterface";
import axios from "axios";
import ProfilePageMixin from "../../../Mixins/ProfilePageMixin";
import InputErrorHandler from "../../../Mixins/InputErrorHandler";
import SweetAlertMixin from "../../../Mixins/SweetAlertMixin";
import PasswordUnit from "../../Units/PasswordUnit.vue";
import ButtonUnit from "../../Units/ButtonUnit.vue";
import InputUnit from "../../Units/InputUnit.vue";
import EmailVerifiedInput from "../../Units/EmailVerifiedInput.vue";

export default defineComponent({
	name: "LoginRegistrationLostPassword",
	components: {InputUnit, ButtonUnit, PasswordUnit, EmailVerifiedInput},
	mixins: [ProfilePageMixin, InputErrorHandler, SweetAlertMixin],
	props: {
		modelValue: {
			type: Object as PropType<RegistrationModelInterface>,
			required: true,
		},
	},
	emits: ['goBack', 'closeModal'],
	setup() {
		return {
			authStore: useAuthStore(),
		}
	},
	data() {
		return {
			isLoading: false,
			email: this.modelValue.email,
			code: '',
			new_password: '',
			formKeys: [
				'lost-password-code',
				'lost-password-new-password',
			],
		}
	},
	methods: {
		reset(): void {
			this.code = '';
			this.new_password = '';
		},
		goBack(): void {
			this.reset();
			this.$emit('goBack');
		},
		lostPassword(): void {
			if (this.hasErrors()) {
				return;
			}
			this.isLoading = true;
			axios.post('/api/forget-password', {
				email: this.email,
				new_password: this.new_password,
				code: this.code
			}).then(response => {
				if (response.status === 200 && response.data.success) {
					this.recaptcha();
				}
			}).catch(() => this.errorSaveAlert()
			).finally(() => this.isLoading = false);
		},
		async recaptcha() {
			if (this.email?.length === 0) {
				return;
			}
			//@ts-ignore
			await this.$recaptchaLoaded();
			//@ts-ignore
			const token = await this.$recaptcha('login');
			this.login(token);
		},
		login(token: string): void {
			this.isLoading = true;
			this.authStore.login(this.email, this.new_password, token).then(async () => {
				this.reset();
				this.$emit('closeModal');
				this.authStore.getMe();
				await this.$router.push({path: this.getSimpleLocalizeRoute('profile')});
				this.customSuccessAlert(this.$root?.translate('success-save-title'), this.$root?.translate('password-page-success-password-modify'));
			}).catch((error) => {
				this.customErrorAlert(this.$root?.translate('alert-error-title'), error.response?.data?.errors?.email);
			}).finally(() => this.isLoading = false);
		},
	},
});
</script>

<style scoped>
</style>
