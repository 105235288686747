import {defineComponent} from "vue";

export default defineComponent({
	inject: ['emitter'],
	data() {
		return {
			modalId: '',	// ezt minden modal-nál ki kell tölteni
		}
	},
	mounted() {
		// @ts-ignore
		this.emitter.on('onModalClose_' + this.modalId, () => this.reset());
	},
	beforeUnmount() {
		// @ts-ignore
		this.emitter.off('onModalShow_' + this.modalId);
		// @ts-ignore
		this.emitter.off('onModalClose_' + this.modalId);
	},
	methods: {
		closeModal(): void {
			// @ts-ignore
			this.emitter.emit('onModalClose_' + this.modalId);
		},
		reset(): void {
			// ha van resetelendő input/változó, miután bezárjuk a modal-t, akkor azt itt kell megadni
		},
	}
});
