<template>
	<div>
		<template v-if="this.options?.name && this.options?.name?.length > 0">
			<label
					:for="this.options?.slug"
					class="input-unit__label"
					:class="{'required': this.options?.required}"
			>
				{{ this.options?.name }}
			</label>
		</template>
		<template v-if="this.options?.standardText">
			<p v-html="this.$root?.translate('profile-email-verified-input-standard-text')" class="mt-2 mb-3"></p>
		</template>
		<div class="email-verified-input">
			<input
					:class="{
						'input-unit': true,
						'error': this.options?.errorState ?? false,
					}"
					type="text"
					:name="this.options?.slug"
					:id="this.options?.slug"
					:value="this.modelValue"
					:placeholder="this.options?.placeholder ?? ''"
					:minlength="this.options?.minlength ?? null"
					:maxlength="this.options?.maxlength ?? null"
					:pattern="this.options?.pattern ?? null"
					:required="this.options?.required ?? false"
					@input="this.$emit('update:modelValue', $event.target.value)"
			/>
			<button-unit
					:options="{
						text: this.timerState.current !== 0 ? this.$root?.translate('profile-email-verified-input-send-again') + this.timerState.current : this.$root?.translate('profile-email-verified-input-get-code'),
						shape: ButtonUnitShape.RECTANGLE,
						bgColor: this.primaryColorVar,
						textColor: this.buttonTextColor,
						disabled: this.timerState.current !== 0 || this.isLoading,
						showLoader: this.isLoading,
						class: 'lg text-nowrap',
					}"
					@click-on-btn="this.recaptcha()"
			/>
		</div>

		<small
				v-show="this.options?.infoText && this.options?.infoText?.length > 0"
				:id="this.options?.slug+'_info'"
				class="mt-1 d-flex input-unit__label--secondary"
		>
			{{ this.options?.infoText }}
		</small>
	</div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {EmailVerifiedInputOptions} from "./Interfaces/EmailVerifiedInputOptions";
import axios from "axios";
import ProfilePageMixin from "../../Mixins/ProfilePageMixin";
import SweetAlertMixin from "../../Mixins/SweetAlertMixin";
import InputUnit from "./InputUnit.vue";
import ButtonUnit from "./ButtonUnit.vue";

export default defineComponent({
	name: "EmailVerifiedInput",
	mixins: [ProfilePageMixin, SweetAlertMixin],
	components: {ButtonUnit, InputUnit},
	data() {
		return {
			isLoading: false,
			maxSec: 15,
			timerState: {
				current: 0,
				state: null as any,
			},
		}
	},
	props: {
		options: {
			type: Object as PropType<EmailVerifiedInputOptions>,
			required: true,
		},
		modelValue: {
			type: String,
			default: '',
			required: true
		},
		email: {
			type: String,
			default: '',
			required: true,
		},
	},
	methods: {
		async recaptcha() {
			this.refGetCode('');

			// TODO: Zs recaptcha
			// // (optional) Wait until recaptcha has been loaded.
			// await this.$recaptchaLoaded()
			// // Execute reCAPTCHA with action "login".
			// const token = await this.$recaptcha('submit')
			// // Do stuff with the received token.
			// this.refGetCode(token);
		},
		refGetCode(token: any): void {
			this.isLoading = true;
			axios.post('/api/confirmation-code', {
				email: this.email,
				// reCaptchaToken: token
			}).then((response) => {

				if (response.status === 200) {
					this.timerState.current = 15;
					this.timerState.state = setInterval(() => {
						this.timerState.current--;
						if (this.timerState.current <= 0) {
							this.timerState.current = 0;
							clearInterval(this.timerState.state);
							this.timerState.state = null;
						}
					}, 1000);
				}
			}).catch(() => {
				this.customErrorAlert(this.$root?.translate('alert-error-title'), this.$root?.translate('profile-email-verified-input-failed'));
			}).finally(() => {
				this.isLoading = false;
			});
		},
	},
});
</script>

<style scoped>
</style>
