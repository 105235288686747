export interface ProfileAdvertsInterface {
	applied: ProfileAdvertInterface[],
	saved: ProfileAdvertInterface[],
}

export class ProfileAdvertsClass implements ProfileAdvertsInterface {
	applied: ProfileAdvertInterface[] = [];
	saved: ProfileAdvertInterface[] = [];
}

export interface ProfileAdvertInterface {
	id: string,
	advert_state: string,
	name: string,
	description: string,
	location: string,
	wage: string,
	task: string[],
	expectations: string[],
	formatted_expiry_date: string,
	url: string,
	index_id: number,
	signedUp: boolean,
}

export class ProfileAdvertClass implements ProfileAdvertInterface {
	id = '';
	advert_state = '';
	name = '';
	description = '';
	location = '';
	wage = '';
	task = [];
	expectations = [];
	formatted_expiry_date = '';
	url = '';
	index_id = -1;
	signedUp = false;
}
