import {defineStore} from "pinia";
import {SiteCheckInterface} from "./Interfaces/SiteCheckInterface";
import axios from "axios";
import {SiteCheckUrls} from "./Utils/SiteCheckUrls";

export const useSiteCheckStore = defineStore({
	id: 'SiteCheckStore',
	state: () => ({
		loaded: false,
		site: {} as SiteCheckInterface,
		isMobileDevice: false,
	}),
	actions: {
		async getSite() {
			this.getDevice();
			return await axios.get(SiteCheckUrls.siteCheck)
				.then(response => {
					if (response.status === 200 && response.data.success === true) {
						this.site = response.data;
					}
				})
				.catch(() => {
				})
				.finally(() => this.loaded = true);
		},
		getDevice(): void {
			const toMatch = [
				/Android/i,
				/webOS/i,
				/iPhone/i,
				/iPad/i,
				/iPod/i,
				/BlackBerry/i,
				/Windows Phone/i
			];
			this.isMobileDevice = toMatch.some((toMatchItem) => {
				return navigator.userAgent.match(toMatchItem);
			});
		}
	},
	getters: {
		isLoaded: (state): boolean => state.loaded,
		currentSite: (state): SiteCheckInterface => state.site,
		isMobile: (state): boolean => state.isMobileDevice
	},
});
