<template>
	<div class="simple-login">
		<form @submit.prevent="login()">
			<div class="form-group mb-3">
				<input-unit
						v-model="form.email"
						ref="loginEmailInputUnit"
						:options="{
                        slug: 'login-email',
                        name: translate('email'),
                        type: 'email',
                        placeholder: translate('email-placeholder'),
                        required: true,
                        autofocus: true,
                    }"
				/>
			</div>
			<div class="form-group mb-4">
				<password-unit
						v-model="form.password"
						ref="loginPasswordInputUnit"
						:options="{
                        slug: 'login-password',
                        name: translate('password'),
                        placeholder: translate('min-8-char'),
                        minlength: 8,
                        required: true,
                    }"
				/>
			</div>
			<div>
				<a class="mt-2 mb-4 login-registration__lost-password-link" @click="$emit('showLostPassword')">
					{{ this.$root?.translate('forget-password') }}
				</a>
			</div>
			<div class="d-flex justify-content-center">
				<button-unit
						:options="{
                        text: this.$root?.translate('simple-login-button'),
                        type: 'submit',
                        class: 'lg mb-4 mb-md-0 login-registration__primary-btn',
                        shape: ButtonUnitShape.ROUNDED,
                        bgColor: this.primaryColorVar,
                        textColor: this.buttonTextColor,
                        disabled: isLoading,
                        showLoader: isLoading,
                    }"
						@click-on-btn=""
				/>
			</div>
		</form>
	</div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import InputUnit from '../../Units/InputUnit.vue';
import PasswordUnit from '../../Units/PasswordUnit.vue';
import ButtonUnit from '../../Units/ButtonUnit.vue';
import ProfilePageMixin from '../../../Mixins/ProfilePageMixin';
import {useAuthStore} from '../../../Stores/Auth/AuthStore';
import SweetAlertMixin from '../../../Mixins/SweetAlertMixin';

export default defineComponent({
	name: "SimpleLogin",
	components: {
		PasswordUnit,
		InputUnit,
		ButtonUnit,
	},
	mixins: [ProfilePageMixin, SweetAlertMixin],
	emits: ['closeModal', 'showLostPassword'],
	setup() {
		return {
			authStore: useAuthStore()
		}
	},
	data() {
		return {
			isLoading: false,
			form: {
				email: '',
				password: ''
			},
		}
	},
	methods: {
		async login(): Promise<void> {
			this.isLoading = true;
			const token = await this.$recaptcha('login');
			this.authStore.login(this.form.email, this.form.password, token).then(async () => {
				await this.authStore.getMe();
				this.$emit('closeModal');
				this.$router.push({path: this.getSimpleLocalizeRoute('profile')});
			}).catch((error) => {
				this.customErrorAlert(this.$root?.translate('alert-error-title'), error.response?.data?.errors?.email);
			}).finally(() => this.isLoading = false);
		},
	}
});
</script>
