export interface ProfileStudentIdInterface {
	number: string,
	expiration: ProfileStudentIdExpirationInterface,
	type?: string,
	front_image?: any,
	back_image?: any,
}

export interface ProfileStudentIdExpirationInterface {
	year: string,
	month: string,
}

export class ProfileStudentIdClass implements ProfileStudentIdInterface {
	number = '';
	expiration = new ProfileStudentIdExpirationClass();
}

export class ProfileStudentIdExpirationClass implements ProfileStudentIdExpirationInterface {
	year = '';
	month = '';
}
