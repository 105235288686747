export interface AddressInterface {
	zip_code: string,
	country: string,
	township: string,
	address: string,
	house_number: string,
	floor: string,
	door: string,
}

export class AddressClass implements AddressInterface {
	zip_code = '';
	country = '';
	township = '';
	address = '';
	house_number = '';
	floor = '';
	door = '';
}
