<template>
	<p class="my-2">
		{{ translate('advert-apply-logged-out-text-info') }}
	</p>
	<div class="row">
		<div class="col-12 my-1">
			<password-unit
					v-model="this.modelValue.password"
					ref="registrationPasswordInputUnit"
					:options="{
							slug: 'registration-password',
							name: this.$root?.translate('password'),
							placeholder: this.$root?.translate('min-8-char'),
							minlength: 8,
							required: true,
							errorState: this.errorsField.password.invalid,
							infoText: this.errorsField.password.errorText,
							autofocus: true,
						}"
			/>
		</div>
		<div class="col-12 my-3 mb-4">
			<email-verified-input
					v-model="this.modelValue.code"
					:email="this.modelValue.email"
					:options="{
									slug: 'register-code',
									name: this.$root?.translate('email-confirm-address'),
									standardText: true,
									placeholder: this.$root?.translate('6-char-code'),
									minlength: 6,
									required: true,
									showLoader: this.isLoading,
									errorState: this.errorsField.code.invalid,
									infoText: this.errorsField.code.errorText,
								}"
			/>
		</div>
	</div>
	<div class="d-flex flex-md-row justify-content-center w-100">

		<button-unit
				:options="{
						text: translate('advert-apply-logged-out-button'),
						type: 'submit',
						shape: ButtonUnitShape.ROUNDED,
						bgColor: this.primaryColorVar,
						textColor: this.buttonTextColor,
						class: 'lg mb-4 mb-md-0 login-registration__primary-btn',
						disabled: this.isLoading,
						showLoader: this.isLoading,
					}"
				@click-on-btn="goActive()"
		/>
	</div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import ProfilePageMixin from "../../../Mixins/ProfilePageMixin";
import InputUnit from "../../Units/InputUnit.vue";
import ButtonUnit from "../../Units/ButtonUnit.vue";
import {useAuthStore} from "../../../Stores/Auth/AuthStore";
import PasswordUnit from "@/WebApp/Components/Units/PasswordUnit.vue";
import EmailVerifiedInput from "@/WebApp/Components/Units/EmailVerifiedInput.vue";
import {RegistrationModelInterface} from "@/WebApp/Interfaces/RegistrationModelInterface";
import InputErrorHandler from "@/WebApp/Mixins/InputErrorHandler";
import SweetAlertMixin from "@/WebApp/Mixins/SweetAlertMixin";
import axios from "axios";

export default defineComponent({
	name: "AdvertApplyLoggedOut",
	components: {EmailVerifiedInput, PasswordUnit, ButtonUnit, InputUnit},
	mixins: [ProfilePageMixin, InputErrorHandler, SweetAlertMixin],
	emits: ['applyAdvert'],
	props: {
		modelValue: {
			type: Object as PropType<RegistrationModelInterface>,
			required: true,
		},
	},
	setup() {
		return {
			authStore: useAuthStore()
		}
	},
	data() {
		return {
			isLoading: false,
			errorsField: {
				email: {
					invalid: false,
					infoText: '',
				},
				password: {
					invalid: false,
					infoText: '',
				},
				code: {
					invalid: false,
					infoText: '',
				},
			},
		}
	},
	methods: {
		goActive() {
			this.errorsField.password.invalid = false;
			this.errorsField.code.invalid = false;
			this.errorsField.email.invalid = false;

			if (this.hasErrors()) {
				return;
			}

			this.isLoading = true;
			console.log(this.modelValue);
			axios.post('/api/users/activation', {
				code: this.modelValue.code,
				password: this.modelValue.password,
				email: this.modelValue.email
			}).then(response => {
				if (response.status === 200 && response.data.success === true) {
					this.$emit('applyAdvert');
					//this.customSuccessAlert('Sikeres aktiválás!', 'Sikeresen aktiváltad a felhasználódat! Kérlek jelentkezz be!');
				}
			}).catch(error => {
				if (error.response.status === 422) {
					const errors = Object.keys(error.response.data.errors);
					errors.forEach(element => {
						this.errorsField[element].invalid = true;
						this.errorsField[element].errorText = error.response.data.errors[element][0];
					});
				} else {
					this.errorSaveAlert();
				}
			}).finally(() => this.isLoading = false);
		}
	}
});
</script>

<style scoped>
</style>
