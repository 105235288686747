<template>
	<Transition name="fade">
		<div v-if="this.show" class="v-modal__overlay" @keyup.esc="this.closeModal()">
			<div class="v-modal__container" :style="'max-width: ' + this.maxWidth">
				<div class="v-modal__header">
					<h3 v-if="this.header?.length > 0">{{ this.header }}</h3>
					<i v-if="this.isClosable" class="fa-solid fa-xmark close-icon" @click="this.closeModal"></i>
				</div>
				<div class="v-modal__body">
					<slot name="body"></slot>
				</div>
			</div>
		</div>
	</Transition>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
	name: "VModal",
	inject: ['emitter'],
	props: {
		name: {
			type: String,
			default: '',
			required: true,
		},
		header: {
			type: String,
			default: '',
			required: false,
		},
		maxWidth: {
			type: String,
			default: '600px',
			required: false,
		},
		isClosable: {
			type: Boolean,
			default: true,
			required: false,
		},
		enableEscapeKeyClose: {
			type: Boolean,
			default: true,
			required: false,
		},
		passedData: {
			type: Function,
			default: undefined,
			required: false,
		}
	},
	data() {
		return {
			show: false,
		}
	},
	mounted() {
		// @ts-ignore
		this.emitter.on('onModalShow_' + this.name, (passedData: any = undefined) => {
			this.show = true;
			if (this.passedData) {
				this.passedData(passedData);
			}
			document.body.style.overflow = 'hidden';
			if (this.enableEscapeKeyClose) {
				document.addEventListener('keyup', this.escapeKeyListener);
			}
		});
		// @ts-ignore
		this.emitter.on('onModalClose_' + this.name, () => {
			this.show = false;
			document.body.style.overflow = 'auto';
			if (this.enableEscapeKeyClose) {
				document.removeEventListener('keyup', this.escapeKeyListener);
			}
		});
	},
	methods: {
		closeModal() {
			this.show = !this.show;
			document.body.style.overflow = 'auto';
			//@ts-ignore
			this.emitter.emit('onModalClose_' + this.name);
		},
		escapeKeyListener(ev: KeyboardEvent) {
			if (ev.key === "Escape" || ev.key === "Esc" || ev.keyCode === 27) {
				this.closeModal();
			}
		},
	}
});
</script>

<style scoped>
</style>
