<template>
	<p class="mb-3">
		{{ translate('advert-activation-or-apply-info-1') }}
	</p>
	<div class="d-flex justify-content-center">
		<button-unit
				:options="{
						text: translate('advert-activation-button'),
						type: 'button',
						shape: ButtonUnitShape.ROUNDED,
						bgColor: this.primaryColorVar,
						textColor: this.buttonTextColor,
						class: 'lg mb-4 mb-md-0 login-registration__primary-btn',
						disabled: this.isLoading,
					}"
				@click-on-btn="this.$emit('goActivation')"
		/>
	</div>
	<div class="d-flex flex-column align-items-center mt-5">
		<h4 class="mb-4">
			{{ translate('advert-activation-without-apply') }}
		</h4>
		<button-unit
				:options="{
						text: translate('advert-activation-apply'),
						type: 'button',
						shape: ButtonUnitShape.ROUNDED,
						bgColor: this.primaryColorVar,
						textColor: this.buttonTextColor,
						class: 'lg mb-4 mb-md-0 login-registration__primary-btn',
						disabled: this.isLoading,
						showLoader: this.isLoading,
					}"
				@click-on-btn="apply()"
		/>

	</div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import ProfilePageMixin from "../../../Mixins/ProfilePageMixin";
import ButtonUnit from "../../Units/ButtonUnit.vue";
import {useAuthStore} from "../../../Stores/Auth/AuthStore";
import {RegistrationModelInterface} from "@/WebApp/Interfaces/RegistrationModelInterface";
import InputErrorHandler from "@/WebApp/Mixins/InputErrorHandler";
import SweetAlertMixin from "@/WebApp/Mixins/SweetAlertMixin";
import {applyAdvert} from "../../../API/advert.api";

export default defineComponent({
	name: "AdvertActivationOrApply",
	components: {ButtonUnit},
	mixins: [ProfilePageMixin, InputErrorHandler, SweetAlertMixin],
	emits: ['closeModal', 'goActivation'],
	props: {
		modelValue: {
			type: Object as PropType<RegistrationModelInterface>,
			required: true,
		},
	},
	setup() {
		return {
			authStore: useAuthStore()
		}
	},
	data() {
		return {
			isLoading: false,
		}
	},
	methods: {
		apply() {
			this.isLoading = true;
			this.showAdvertLoadingAlert();

			applyAdvert(this.modelValue.advert_id, this.modelValue.email).then(data => {
				if (data) {
					sessionStorage.setItem('lastAppliedAdvertName', data.advert_name);
					window.location.href = `/karrier?utm_event=job_application&utm_advert=${data.index_id}&utm_jobcategory=${data.category}&success`;
				}
			}).catch((error: any) => {
				if (error.status === 409) {
					this.customErrorAlert(this.translate('no-employee-applied-error'), this.translate('no-employee-applied-error'));
				} else if (error.status === 504) {
					this.customErrorAlert(this.translate('ops-error-title'), this.translate('ops-error-title'));
				} else {
					this.customErrorAlert(this.translate('failed-applied-error'), this.translate('failed-applied-error'));
				}
			}).finally(() => {
				this.isLoading = false;
			});
		},
	}
});
</script>

<style scoped>
</style>
