<template>
	<div class="row advert-list__selected-search-item gap-2 px-3 py-1">
		<div class="col-auto px-0">
			<span class="advert-list__selected-search-item__text">{{ item.name }}</span>
		</div>
		<div class="advert-list__selected-search-item__button col-auto px-0"
			 @click="this.callback(item)"
		>
			<span class="advert-list__selected-search-item__text">x</span>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {AdvertCategoryClass, AdvertCategoryInterface} from "../Interfaces/AdvertCategoryInterface";
import {ButtonUnitShape} from "../../../Components/Units/Interfaces/ButtonUnitShape";
import ButtonUnit from "../../../Components/Units/ButtonUnit.vue";

export default defineComponent({
	name: "AdvertListSelectedSearchItemButton",
	components: {ButtonUnit},
	props: {
		item: {
			type: Object as AdvertCategoryInterface,
			require: true,
			default: new AdvertCategoryClass(),
		},
		callback: {
			type: Function as (item) => {},
			require: true,
			default: (item) => console.log('Item selected: ', item),
		}
	},
	computed: {
		ButtonUnitShape(): ButtonUnitShape {
			return ButtonUnitShape
		},
		ButtonColor() {
			return 'white';
		},
		ButtonTextColor() {
			return `var(--color-profile)`;
		},
		ButtonBorderColor() {
			return `var(--color-profile)`;
		}
	},
})

</script>

<style scoped>
.advert-list__selected-search-item {
	border: 1px solid v-bind(ButtonBorderColor);
	border-radius: 20px;
	background: v-bind(ButtonColor);
	color: v-bind(ButtonTextColor);
	align-content: center;
	align-items: center;
	justify-content: center;
	justify-items: center;
	min-height: 25px;
}

.advert-list__selected-search-item__text {
	font-size: 12px;
}

.advert-list__selected-search-item__button {
	cursor: pointer;
}

</style>
