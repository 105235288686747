import axios, {AxiosInstance} from 'axios';
import Cookie from "js-cookie";

let defaultHeaders: any = {
	'Content-Type': 'application/json'
}
if (Cookie.get('p_jwt_session')) {
	defaultHeaders['Authorization'] = 'Bearer ' + atob(Cookie.get('p_jwt_session') ?? '');
}

const axiosClient: AxiosInstance = axios.create({
	baseURL: '/api',
	headers: defaultHeaders
});

export default axiosClient;
