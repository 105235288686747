import {defineComponent} from "vue";
import {UserInterface} from "../Interfaces/UserInterface";
import axios from "axios";

export default defineComponent({
	computed: {
		currentUser(): UserInterface {
			//@ts-ignore
			return this.authStore.currentUser;
		},
	},
	methods: {
		userFileUpload(file: string | Blob, filename: string, tag: string, email: string): Promise<any> {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('filename', filename);
			formData.append('tag', tag);
			formData.append('email', email);
			return axios.post('/api/employee/file-upload', formData, {
				headers: {'Content-Type': 'application/x-www-form-urlencoded'}
			});
		},
	}
});
