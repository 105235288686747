export const LayoutCssVariables = {
	page: {
		bgColor: 'color-page-bg',
		profile: 'color-profile',
	},
	header: {
		bgColor: 'color-header-bg',
		textColor: 'color-header-text',
		// buttonShape: 'header-button-shape', //TODO
		buttonBgColor: 'color-header-button-bg',
		buttonTextColor: 'color-header-button-text',
	},
	footer: {
		primaryBgColor: 'color-footer-primary-bg',
		sectionHeaderTextColor: 'color-footer-section-header-text',
		sectionContentTextColor: 'color-footer-section-content-text',
		secondaryBgColor: 'color-footer-secondary-bg',
		secondaryTextColor: 'color-footer-secondary-text',
	},
};
