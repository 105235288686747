<template>
	<p class="mt-5 mb-4">{{ this.$root?.translate('please-enter-email') }}</p>

	<form @submit.prevent="this.checkEmail()">
		<div class="row">
			<div class="col-12">
				<input-unit
						v-model="this.modelValue.email"
						ref="registrationEmailInputUnit"
						:options="{
							slug: 'registration-email',
							name: this.$root?.translate('email'),
							type: 'email',
							placeholder: translate('email-placeholder'),
							required: true,
							errorState: this.errors.email.invalid,
							infoText: this.errors.email.infoText,
							autofocus: true,
						}"
				/>
			</div>
		</div>

		<p class="my-4">{{ this.$root?.translate('need-password') }}</p>
		<social-action-component :is-apply="modelValue.hasOwnProperty('advert_id')"/>
		<div class="d-flex flex-column-reverse flex-md-row align-items-center justify-content-md-between">
			<button-unit
					:options="{
						text: this.$root?.translate('cancel'),
						shape: ButtonUnitShape.ROUNDED,
						textColor: this.primaryColorVar,
						bgColor: '',
						class: 'lg bordered-lg login-registration__secondary-btn',
						disabled: this.isLoading,
					}"
					@click-on-btn="this.$emit('closeModal')"
			/>
			<button-unit
					:options="{
						text: this.$root?.translate('go-next'),
						type: 'submit',
						shape: ButtonUnitShape.ROUNDED,
						bgColor: this.primaryColorVar,
						textColor: this.buttonTextColor,
						class: 'lg mb-4 mb-md-0 login-registration__primary-btn',
						disabled: this.isLoading,
						showLoader: this.isLoading,
					}"
					@click-on-btn="this.checkEmail()"
			/>
		</div>
	</form>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {RegistrationModelInterface} from "../../../Interfaces/RegistrationModelInterface";
import ProfilePageMixin from "../../../Mixins/ProfilePageMixin";
import InputUnit from "../../Units/InputUnit.vue";
import ButtonUnit from "../../Units/ButtonUnit.vue";
import SocialActionComponent from "@/WebApp/Components/SocialMedia/SocialActionComponent.vue";

export default defineComponent({
	name: "LoginRegistrationEmail",
	components: {SocialActionComponent, ButtonUnit, InputUnit},
	mixins: [ProfilePageMixin],
	props: {
		modelValue: {
			type: Object as PropType<RegistrationModelInterface>,
			required: true,
		},
		isLoading: {
			type: Boolean,
			required: true,
		},
	},
	emits: ['closeModal', 'checkEmail'],
	data() {
		return {
			errors: {
				email: {
					invalid: false,
					infoText: '',
				},
			},
		}
	},
	methods: {
		checkEmail(): void {
			//@ts-ignore
			this.errors.email.invalid = !this.$refs.registrationEmailInputUnit.$refs['registration-email'].validity.valid;
			this.errors.email.infoText = '';

			if (this.errors.email.invalid) {
				//@ts-ignore
				this.errors.email.infoText = this.$refs.registrationEmailInputUnit.$refs['registration-email'].validationMessage;
			} else {
				this.$emit('checkEmail');
			}
		}
	},
});
</script>

<style scoped>
</style>
