<template>
	<div v-if="this.languages.length > 1" class="d-flex align-items-center">
		<select v-if="type === 'dropdown'" name="language" id="language" @change="switchLanguage()" v-model="this.selectedLanguage" class="mx-2 language-select">
			<option v-for="lang in this.languages" :value="lang.slug.toLowerCase()">{{ lang.slug.toUpperCase() }}</option>
		</select>
		<div v-else class="mx-2">
			<img v-for="lang in languages" :src="lang.flag" :alt="lang.title" width="25" class="mx-1" @click="switchLanguage(lang.slug.toLowerCase())">
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {useLanguagesStore} from "../Stores/LanguagesStore";
import {useSiteCheckStore} from "../Stores/SiteCheck/SiteCheck";
import Cookie from "js-cookie";

export default defineComponent({
	name: "LanguageSelect",
	setup() {
		return {
			languageStore: useLanguagesStore(),
			siteStore: useSiteCheckStore()
		}
	},
	props: {
		textColor: {
			type: String,
			default: '',
			required: true,
		},
		bgColor: {
			type: String,
			default: '',
			required: true,
		},
		type: {
			type: String,
			default: 'dropdown',
			required: false
		}
	},
	data() {
		return {
			selectedLanguage: '',
		}
	},
	mounted() {
		this.selectedLanguage = Cookie.get('lang') ?? this.defaultLang;
	},
	computed: {
		Cookie() {
			return Cookie
		},
		currentLang(): string {
			return this.languageStore.currentLang;
		},
		defaultLang(): string {
			return this.languageStore.defaultLang;
		},
		languages() {
			// let data = this.languageStore.languageList;
			return this.languageStore.languageList;
			//return Object.keys(data).map(element => element.toUpperCase());
		},
		textColorVar(): string {
			return `var(--${this.textColor})`;
		},
	},
	watch: {
		currentLang() {
			this.selectedLanguage = this.currentLang;
		}
	},
	methods: {
		switchLanguage(selectedLang: any = null): void {
			let lang = selectedLang ?? this.selectedLanguage.toLowerCase();
			Cookie.set('lang', lang);
			window.location.href = '/';
		}
	},
});
</script>

<style scoped>
.language-select {
	color: v-bind(textColorVar);
}

.language-select option {
	background-color: v-bind(bgColor) !important;
}

.language-select option:checked {
	background-color: v-bind(bgColor) !important;
}
</style>
