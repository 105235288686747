export interface AdvertCategoryInterface {
	id: string,
	name: string,
	slug: string,
	children: AdvertCategoryInterface[],
}

export class AdvertCategoryClass implements AdvertCategoryInterface {
	id = '';
	slug= '';
	name = '';
	children = [];
}
