import {ProfilePersonalClass, ProfilePersonalInterface} from "./ProfilePersonalInterface";
import {ProfileNotificationClass, ProfileNotificationInterface} from "./ProfileNotificationInterface";
import {ProfileStudentClass, ProfileStudentInterface} from "./ProfileStudentInterface";
import {ProfileAdvertsClass, ProfileAdvertsInterface} from "./ProfileAdvertsInterface";
import {ProfileDocumentsClass, ProfileDocumentsInterface} from "./ProfileDocumentsInterface";
import {ProfileEducationsClass, ProfileEducationsInterface} from "./ProfileEducationsInterface";
import {ProfileSearchClass, ProfileSearchInterface} from "./ProfileSearchInterface";
import {SiteProfileSettingsClass, SiteProfileSettingsInterface} from "./SiteProfileSettingsInterface";
import {ProfileOtherInterface, ProfileOtherInterClass} from "./ProfileOtherInterface";

export interface UserSDKInterface {
	personal: ProfilePersonalInterface,
	notification: ProfileNotificationInterface,
	verified: boolean,
	student: ProfileStudentInterface,
	adverts: ProfileAdvertsInterface,
	documents: ProfileDocumentsInterface,
	educations: ProfileEducationsInterface,
	searches: ProfileSearchInterface,
	settings: SiteProfileSettingsInterface,
	other: ProfileOtherInterface,
}

export class UserSDKClass implements UserSDKInterface {
	personal = new ProfilePersonalClass();
	notification = new ProfileNotificationClass();
	verified = false;
	student = new ProfileStudentClass();
	adverts = new ProfileAdvertsClass();
	documents = new ProfileDocumentsClass();
	educations = new ProfileEducationsClass();
	searches = new ProfileSearchClass();
	settings = new SiteProfileSettingsClass()
	other = new ProfileOtherInterClass();
}
