import {DriverLicenseInterface} from "../Views/ProfilePageModule/Interfaces/DriverLicenseInterface";
import {EducationItemInterface} from "../Views/ProfilePageModule/Interfaces/EducationItemInterface";
import {ItLearningInterface} from "../Views/ProfilePageModule/Interfaces/ItLearningInterface";
import {LanguageExamInterface} from "../Views/ProfilePageModule/Interfaces/LanguageExamInterface";
import {WorkExperienceInterface} from "../Views/ProfilePageModule/Interfaces/WorkExperienceInterface";

export interface ProfileEducationsInterface {
	educations: EducationItemInterface[],
	work_experience: WorkExperienceInterface[],
	language_exams: LanguageExamInterface[],
	driver_license: DriverLicenseInterface[],
	it_learnings: ItLearningInterface[],
	other_practice: string,
}

export class ProfileEducationsClass implements ProfileEducationsInterface {
	educations = [];
	work_experience = [];
	language_exams = [];
	driver_license = [];
	it_learnings = [];
	other_practice = '';
}
