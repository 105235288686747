export interface ProfileSearchInterface {
    id: string,
    name: string,
    path: string,
}

export class ProfileSearchClass implements ProfileSearchInterface {
    id = '';
    name = '';
    path = '';
}
