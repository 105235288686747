import {defineStore} from 'pinia';
import Cookie from "js-cookie";
import axios from "axios";

export const useLanguagesStore = defineStore({
	id: 'LanguagesStore',
	state: () => ({
		defaultLangSource: '',
		currentLangSource: '',
		translateKeyPairList: [],
		languages: [],
		loaded: false,
	}),
	actions: {
		async getTranslationList(lang: string) {
			this.loaded = false;
			axios.get(`/api/localize/${lang}`).then(response => {
				this.translateKeyPairList = response.data.localize;
				this.languages = response.data.langs;
				this.defaultLangSource = response.data.default_language;
				this.currentLangSource = Cookie.get('lang') ?? response.data.default_language;
				this.loaded = true;
			});
		},
	},
	getters: {
		defaultLang: state => state.defaultLangSource,
		currentLang: state => state.currentLangSource,
		translateList: state => state.translateKeyPairList,
		languageList: state => state.languages,
		isLoaded: (state): boolean => state.loaded,
	}
});
