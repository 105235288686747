<template>
  <button
    :type="options?.type ?? 'button'"
    :class="{
      'button-unit': true,
      [options?.shape ?? '']: options?.shape?.length > 0,
      [options?.class ?? '']: true,
    }"
    :disabled="options?.disabled"
    @click="$emit('clickOnBtn')"
  >
    <i
      v-if="options?.showLoader ?? false"
      class="fa-solid fa-spinner fa-spin"
    ></i>
    <template v-else>
      <span v-if="options?.text?.length > 0">{{ options?.text }}</span>
    </template>
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { ButtonUnitOptions } from './Interfaces/ButtonUnitOptions';

export default defineComponent({
  name: 'ButtonUnit',
  emits: ['clickOnBtn'],
  props: {
    options: {
      type: Object as PropType<ButtonUnitOptions>,
      style: Object as PropType<CSSStyleDeclaration>,
      required: true,
    },
  },
  computed: {
    textColor(): string {
      return this.options?.textColor ?? '';
    },
    bgColor(): string {
      return this.options?.bgColor;
    },
  },
});
</script>

<style scoped>
.button-unit {
  color: v-bind(textColor);
  background-color: v-bind(bgColor);
}
</style>
