<template>
	<v-modal :name="this.modalId" :header="modalTitle" class="login-registration__modal" :max-width="'600px'">
		<template #body>
			<login-registration-stepper :steps="this.steps" v-if="showStepper"/>
			<template v-if="!this.currentUser.hasOwnProperty('email')">
				<advert-apply-email
						v-if="this.currentStep === 'email'"
						v-model="this.model"
						:isLoading="this.isLoading"
						@checkEmail="this.checkEmail()"
						@closeModal="this.closeModal()"
				/>

				<login-registration-password
						v-if="this.currentStep === 'password'"
						v-model="this.model"
						@showLostPassword="this.currentStep = 'lost-password'"
						@goBack="this.goBack('email')"
						@closeModal="this.closeModal()"
				/>

				<login-registration-form
						v-if="this.currentStep === 'form'"
						v-model="this.model"
						@goBack="this.goBack('email')"
						@closeModal="this.closeModal()"
				/>

				<login-registration-lost-password
						v-if="this.currentStep === 'lost-password'"
						v-model="this.model"
						@goBack="this.goBack('email')"
						@closeModal="this.closeModal()"
				/>

				<advert-apply-email
						v-if="!this.currentUser && this.currentStep === 'email'"
						v-model="this.model"
						:isLoading="this.isLoading"
						@checkEmail="this.checkEmail()"
						@closeModal="this.closeModal()"
				/>

				<advert-activation-or-apply
						v-if="this.currentStep === 'activation-or-apply'"
						v-model="this.model"
						@closeModal="this.closeModal()"
						@goActivation="goActivation()"
				/>

				<advert-apply-logged-out
						v-if="this.currentStep === 'activation'"
						v-model="this.model"
						@applyAdvert="this.apply()"
				/>
			</template>

			<advert-apply-logged-in
					v-else
					:isLoading="this.isLoading"
					@applyAdvert="this.apply()"
					@closeModal="this.closeModal()"
			/>
		</template>
	</v-modal>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import VModalMixin from "../../../Mixins/VModalMixin";
import VModal from "../../VModal.vue";
import LoginRegistrationStepper from "../LoginRegistration/LoginRegistrationStepper.vue";
import InputUnit from "../../Units/InputUnit.vue";
import axios from "axios";
import ButtonUnit from "../../Units/ButtonUnit.vue";
import ProfilePageMixin from "../../../Mixins/ProfilePageMixin";
import AdvertApplyEmail from "./AdvertApplyEmail.vue";
import {UserInterface} from "../../../Interfaces/UserInterface";
import {useAuthStore} from "../../../Stores/Auth/AuthStore";
import LoginRegistrationPassword from "../LoginRegistration/LoginRegistrationPassword.vue";
import LoginRegistrationForm from "../LoginRegistration/LoginRegistrationForm.vue";
import {RegistrationModelClass} from "../../../Interfaces/RegistrationModelInterface";
import SweetAlertMixin from "../../../Mixins/SweetAlertMixin";
import AdvertApplyLoggedIn from "./AdvertApplyLoggedIn.vue";
import AdvertApplyLoggedOut from "./AdvertApplyLoggedOut.vue";
import AdvertActivationOrApply from "./AdvertActivationOrApply.vue";
import Cookie from "js-cookie";
import {applyAdvert} from "../../../API/advert.api";
import LoginRegistrationLostPassword from "@WebApp/Components/Modals/LoginRegistration/LoginRegistrationLostPassword.vue";

export default defineComponent({
	name: "AdvertApplyModal",
	mixins: [VModalMixin, ProfilePageMixin, SweetAlertMixin],
	inject: ['emitter'],
	setup() {
		return {
			authStore: useAuthStore()
		}
	},
	components: {
		LoginRegistrationLostPassword,
		AdvertActivationOrApply,
		AdvertApplyLoggedOut,
		AdvertApplyLoggedIn,
		LoginRegistrationForm,
		LoginRegistrationPassword,
		AdvertApplyEmail,
		ButtonUnit,
		InputUnit,
		LoginRegistrationStepper,
		VModal
	},

	data() {
		return {
			modalId: 'advert-apply-modal',
			isLoading: false,
			steps: [
				{
					title: 'default-data',
					active: true,
					done: false,
				},
				{
					title: 'login-and-personal-data',
					active: false,
					done: false,
				},
			],
			currentStep: 'email' as 'email' | 'form' | 'password' | 'activation' | 'activation-or-apply',
			model: new RegistrationModelClass(),
		}
	},
	mounted() {
		//@ts-ignore
		this.emitter.on('advertJobIndex', (advertId: string) => {
			//@ts-ignore
			this.model.advert_id = advertId;
			if (!this.isLoggedIn) {
				Cookie.set('advertId', String(advertId), {expires: 15});
			}
		});
		//@ts-ignore
		this.emitter.on('onModalClose_advert-apply-modal', () => {
			this.currentStep = 'email';
			this.model = new RegistrationModelClass();
		});
	},
	beforeUnmount() {
		//@ts-ignore
		this.emitter.off('advertJobIndex');
		//@ts-ignore
		this.emitter.off('onModalClose_advert-apply-modal');
		//@ts-ignore
		this.model.advert_id = undefined;
	},
	computed: {
		currentUser(): UserInterface {
			let currentUser = this.authStore.currentUser;
			if (currentUser.hasOwnProperty('email')) {
				this.steps[0].active = false;
				this.steps[0].done = true;
				this.steps[1].active = true;
			}
			return currentUser;
		},
		isLoggedIn(): boolean {
			return this.authStore.isLoggedIn;
		},
		showStepper(): boolean {
			return this.currentStep !== 'activation' && this.currentStep !== 'activation-or-apply' && this.currentStep !== 'lost-password';
		},
		modalTitle(): string {
			return this.currentStep === 'lost-password' ? this.translate('new-password-title') : this.translate('advert-application');
		}
	},
	methods: {
		checkEmail(): void {
			this.isLoading = true;
			axios.post('/api/check-email', {email: this.model.email ?? this.currentUser.email}).then(response => {
				if (response.status === 200 && response.data.success && response.data.status === 'active') {
					this.steps[0].active = false;
					this.steps[0].done = true;
					this.steps[1].active = true;
					this.currentStep = 'password';
				}

				if (response.status === 200 && response.data.success && response.data.status === 'inactive') {
					this.currentStep = 'activation-or-apply';
				}

			}).catch((error) => {
				if (error.response.status === 404 && error.response.data.success) {
					this.steps[0].active = false;
					this.steps[0].done = true;
					this.steps[1].active = true;
					this.currentStep = 'form';
				}

			}).finally(() => this.isLoading = false);
		},
		goBack(step: 'email' | 'password' | 'form'): void {
			if (step === 'email') {
				this.currentStep = 'email';
				this.steps[0].active = true;
				this.steps[0].done = false;
				this.steps[1].active = false;
			}
		},
		apply(): void {
			this.isLoading = true;
			this.showAdvertLoadingAlert();


			axios.post(`/api/adverts/apply/${this.model.advert_id}`, {email: this.currentUser?.email ?? this.model.email}).then((response: any) => {
				if (response.status === 200 && response.data.success === true) {

					if (window.dataLayer && response.data.hasOwnProperty('dataLayer')) {
						window.dataLayer.push(JSON.stringify(response.data.dataLayer));
					}
					sessionStorage.setItem('lastAppliedAdvertName', response.data.advert_name);
					window.location.href = `/karrier?utm_event=job_application&utm_advert=${response.data.index_id}&utm_jobcategory=${response.data.category}&success`;
				}
			}).catch((error: any) => {
				if (error.status === 409) {
					this.customErrorAlert(this.translate('no-employee-applied-error'), this.translate('no-employee-applied-error'));
				} else {
					this.customErrorAlert(this.translate('failed-applied-error'), this.translate('failed-applied-error'));
				}
			}).finally(() => {
				this.isLoading = false;
			});
		},
		goActivation(): void {
			this.currentStep = 'activation';
		}
	},
});
</script>
