<template>
	<label v-if="options?.name" :for="options?.slug" class="vue-select-unit__label" :class="{ required: options?.required }">
		{{ options?.name }}
	</label>
	<v-select
			class="vue-select-unit"
			v-model="selected"
			:id="options?.slug"
			:class="{
			[options?.class ?? '']: true,
			error: options?.errorState,
		}"
			:options="items"
			:placeholder="options?.placeholder ?? ''"
			:label="options?.label ?? 'label'"
			:dropdown-should-open="options?.dropdownShouldOpenFn"
			:create-option="options?.createOptionFn"
			:reduce="options?.reduceFn"
			:get-option-label="customGetOptionLabel"
			:clearable="options?.clearable ?? false"
			:taggable="options?.taggable ?? false"
			:searchable="options?.searchable ?? true"
			:multiple="options?.multiple ?? false"
			:required="options?.required ?? false"
			:disabled="options?.disabled ?? false"
			:selectable="options?.selectable"
			:get-option-key="options?.getOptionKey"
			:no-drop="options?.noDrop"
			:reset-on-options-change="options?.resetOnOptionsChange"
			@search="onSearch"
			@open="options?.onOpen"
	>
		<template #search="{ attributes, events }">
			<input
					:maxlength="options?.maxlength"
					:class="{
					vs__search: true,
					'vs__search-hide': !(options?.showInput ?? true),
				}"
					v-bind="attributes"
					v-on="events"
			/>
		</template>
		<template #open-indicator v-if="options?.showOpenIndicator ?? true">
			<i class="fa fa-chevron-down vue-select-unit-indicator-icon"></i>
		</template>
		<template #no-options="{ search, searching, loading }">
			<template v-if="axiosList.length === 0 && search.length >= 3">{{ translate('not-found-dropdown') }}</template>
			<template v-else> {{ translate('please-type-enter-here') }}</template>
		</template>
		<template #open-indicator="{ attributes }">
			<i class="fa-solid fa-chevron-down" v-bind="attributes"></i>
		</template>
	</v-select>

	<small v-show="options?.infoText && options?.infoText?.length > 0" :id="options?.slug + '_info'" class="mt-1 d-flex vue-select-unit__label--secondary">
		{{ options?.infoText }}
	</small>
</template>

<script lang="ts">
import axios from "axios";
import {defineComponent, PropType} from "vue";
import {VueSelectUnitOptions} from "./Interfaces/VueSelectUnitOptions";

export default defineComponent({
	name: "VueSelectUnit",
	emits: ["update:modelValue"],
	props: {
		options: {
			type: Object as PropType<VueSelectUnitOptions>,
			required: true,
		},
		modelValue: {
			type: [String, Array, Object],
			default: "",
			required: false,
		},
	},
	data() {
		return {
			axiosList: [] as any,
			selected: null as any,
		};
	},
	watch: {
		selected() {
			this.$emit("update:modelValue", this.selected);
		},
		modelValue() {
			this.selected = this.modelValue;
			if (this.options?.hasOwnProperty("axiosSearchUrl") && this.modelValue.length !== 0) {
				this.onSearch(this.modelValue, (state: any) => {
					// fake function
				});
			}
		},
		axiosList: {
			handler() {
				if (!this.options?.multiple && this.options?.hasOwnProperty("axiosSearchUrl") && this.modelValue !== null && this.modelValue?.length !== 0 && this.axiosList?.length > 0) {
					this.selected = this.axiosList[0];
				}
			},
			deep: true,
		},
	},
	mounted() {
		if (typeof this.modelValue === "object" && this.modelValue !== null && Object.keys(this.modelValue).length > 0) {
			this.selected = this.modelValue;
		}
	},
	methods: {
		onSearch(search: any, loading: any) {
			if (!this.options?.hasOwnProperty("axiosSearchUrl")) {
				return;
			}
			if (search.length >= 3) {
				loading(true);
				axios
						.get(`/${this.options?.axiosSearchUrl}?${this.options?.axiosSearchKeyword ?? "q"}=${search}`)
						.then((response: any) => {
							if (response.status === 200 && response.data.success === true) {
								this.axiosList = (this.options?.axiosSearchDataMapper ?? ((response: any) => response.data.items))(response);
								loading(false);
							}
						})
						.finally(() => loading(false));
			}
		},
		customGetOptionLabel(option: any) {
			if (typeof option === "object") {
				const lbl = this.options?.label ?? "label";
				if (!option.hasOwnProperty(lbl) && Object.keys(option).length > 0) {
					return console.warn(
							`[vue-select warn]: Label key "option.${lbl}" does not` + ` exist in options object ${JSON.stringify(option)}.\n` + "https://vue-select.org/api/props.html#getoptionlabel"
					);
				}
				return option[lbl];
			}
			return option;
		},
	},
	computed: {
		items() {
			if (this.options?.hasOwnProperty("axiosSearchUrl")) {
				return this.axiosList;
			}
			return this.options?.options;
		},
	},
});
</script>

<style scoped></style>
