export interface DateInterface {
	year: string,
	month: string,
	day: string,
}

export class DateClass implements DateInterface {
	year = '';
	month = '';
	day = '';
}
