<template>
	<template v-if="this.options?.hasOwnProperty('name') && this.options?.name?.length > 0">
		<label
				:for="this.options?.slug"
				class="password-unit__label"
				:class="{'required': this.options?.required}"
		>
			{{ this.options?.name }}
		</label>
	</template>

	<div
			class="password-unit__container"
			:class="{
				[this.options?.class ?? '']: true,
				'focused': this.focused,
				'readonly': this.options?.readonly ?? false,
				'error': this.options?.errorState,
			}"
	>
		<input
				:type="this.inputType"
				:name="this.options?.slug"
				:id="this.options?.slug"
				class="password-unit"
				:value="this.modelValue"
				:ref="this.options?.slug"
				:placeholder="this.options?.placeholder ?? ''"
				:minlength="this.options?.minlength ?? null"
				:maxlength="this.options?.maxlength ?? null"
				:pattern="this.options?.pattern ?? null"
				:required="this.options?.required ?? false"
				:readonly="this.options?.readonly ?? false"
				@input='this.$emit("update:modelValue", $event.target.value)'
				@focus="this.focused=true"
				@blur="this.focused=false"
		>
		<span class="password-unit__suffix" @click="this.togglePassword">
            <i class="fa-solid" :class="this.inputType === 'password' ? 'fa-eye' : 'fa-eye-slash'"></i>
		</span>
	</div>

	<small
			v-show="this.options?.hasOwnProperty('infoText') && this.options?.infoText?.length > 0"
			:id="this.options?.slug+'_info'"
			class="mt-1 password-unit__label--secondary"
	>
		{{ this.options?.infoText }}
	</small>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {PasswordUnitOptions} from "./Interfaces/PasswordUnitOptions";

export default defineComponent({
	name: "PasswordUnit",
	props: {
		options: {
			type: Object as PropType<PasswordUnitOptions>,
			required: true
		},
		modelValue: {
			type: String,
			default: '',
			required: true
		},
	},
	data() {
		return {
			inputType: 'password',
			focused: false,
		}
	},
	mounted() {
		if (this.options?.autofocus) {
			(this.$refs[this.options?.slug] as HTMLElement)?.focus();
		}
	},
	methods: {
		togglePassword() {
			this.inputType = this.inputType === 'text' ? 'password' : 'text';
			this.focused = true;
		},
	},
});
</script>

<style scoped>
</style>
