import {defineStore} from 'pinia';
import axios, {AxiosResponse} from "axios";
import {ISearchDataItem, ISearchDataResponse, ISearchFieldItem} from "./Interfaces/ISearchDataResponse";

export const useSearchDataStore = defineStore({
	id: 'SearchDataStore',
	state: () => ({
		fieldList: [] as ISearchFieldItem[] | undefined,
		categoryList: [] as ISearchDataItem[] | undefined,
		countryList: [] as ISearchDataItem[] | undefined,
		countyList: [] as ISearchDataItem[] | undefined,
		cityList: [] as ISearchDataItem[] | undefined,
		countySeatList: [] as ISearchDataItem[] | undefined,
		bpDistrictList: [] as ISearchDataItem[] | undefined,
		territorialList: [] as ISearchDataItem[] | undefined,
		employmentTypeList: [] as ISearchDataItem[] | undefined,
		experienceList: [] as ISearchDataItem[] | undefined,
		educationList: [] as ISearchDataItem[] | undefined,
		workScheduleList: [] as ISearchDataItem[] | undefined,
		employeeCategoryList: [] as ISearchDataItem[] | undefined,
		loaded: false as Boolean
	}),
	actions: {
		async getSearchDataLoad(query: string[]) {
			this.loaded = false;
			await axios.post(`/api/search-data`, {items: query}).then((response: AxiosResponse<ISearchDataResponse>) => {
				if (response.status === 200 && response.data.success === true) {
					this.fieldList = response.data.items.fields;
					this.categoryList = response.data.items.data.categories;
					this.countryList = response.data.items.data.countries;
					this.countyList = response.data.items.data.counties;
					this.cityList = response.data.items.data.cities;
					this.countySeatList = response.data.items.data.countySeats;
					this.bpDistrictList = response.data.items.data.bpDistricts;
					this.territorialList = response.data.items.data.territorial;
					this.employmentTypeList = response.data.items.data.employmentTypes;
					this.employeeCategoryList = response.data.items.data.employeeCategories;
					this.experienceList = response.data.items.data.experiences;
					this.educationList = response.data.items.data.educations;
					this.workScheduleList = response.data.items.data.workSchedule;
					this.loaded = true;
				}
			}).catch(error => console.error(error));
		},
	},
	getters: {
		fields: state => state.fieldList,
		categories: state => state.categoryList,
		countries: state => state.countryList,
		counties: state => state.countyList,
		cities: state => state.cityList,
		bpDistricts: state => state.bpDistrictList,
		countySeats: state => state.countySeatList,
		territorials: state => state.territorialList,
		employmentTypes: state => state.employmentTypeList,
		employeeCategories: state => state.employeeCategoryList,
		experiences: state => state.experienceList,
		educations: state => state.educationList,
		workSchedules: state => state.workScheduleList,
		isLoaded: state => state.loaded
	}
});
