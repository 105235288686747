<template>
	<a
			v-if="this.options.isExternalLink"
			:href="this.options.link"
			target="_blank"
			:class="this.options.class ?? ''"
			@click="this.$emit('clickOnLink')"
	>
		<slot name="prefix"></slot>
		{{ this.options.title }}
		<slot name="suffix"></slot>
	</a>

	<router-link
			v-else
			:to="{ path: this.options.link }"
			:class="this.options.class ?? ''"
			@click="this.$emit('clickOnLink')"
	>
		<slot name="prefix"></slot>
		{{ this.options.title }}
		<slot name="suffix"></slot>
	</router-link>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {VLinkInterface} from "./Interfaces/VLinkInterface";

export default defineComponent({
	name: "VLink",
	emits: ['clickOnLink'],
	props: {
		options: {
			type: Object as PropType<VLinkInterface>,
			required: true,
		},
	},
});
</script>

<style scoped>
</style>
