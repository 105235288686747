import {EducationMethodEnum} from "./EducationMethodEnum";
import {EducationStatusEnum} from "./EducationStatusEnum";

export interface ProfileCurrentStudyInterface {
	name: string,
	type: string,
	grade: string,
	specialization: string,
	education: ProfileCurrentStudyEducationInterface,
}

export class ProfileCurrentStudyClass implements ProfileCurrentStudyInterface {
	name = '';
	type = '';
	grade = '';
	specialization = '';
	education = new ProfileCurrentStudyEducationClass();
}

export interface ProfileCurrentStudyEducationInterface {
	method: EducationMethodEnum,
	status: EducationStatusEnum,
}

export class ProfileCurrentStudyEducationClass implements ProfileCurrentStudyEducationInterface {
	method = EducationMethodEnum.FULL_TIME;
	status = EducationStatusEnum.PASSIVE;
}
