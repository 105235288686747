<template>
	<ul class="login-registration-stepper__container">
		<li v-for="(step, index) in this.steps" :class="{'login-registration-stepper__step': true, 'active': step.active, 'done': step.done}">
			<h5 class="step-number">
				<template v-if="!step.done">{{ index + 1 }}</template>
				<i v-else class="fa-solid fa-check"></i>
			</h5>
			<span class="step-title">{{ this.$root?.translate(step.title) }}</span>
		</li>
	</ul>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import ProfilePageMixin from "../../../Mixins/ProfilePageMixin";

export default defineComponent({
	name: "LoginRegistrationStepper",
	mixins: [ProfilePageMixin],
	props: {
		steps: {
			type: Array as PropType<{ title: string, active: boolean, done: boolean }[]>,
			required: true,
		},
	},
});
</script>

<style scoped>
.login-registration-stepper__step.active .step-number {
	color: v-bind(primaryColorVar);
	border-color: v-bind(primaryColorVar);
}

.login-registration-stepper__step.active .step-title {
	color: v-bind(primaryColorVar);
}

.login-registration-stepper__step.done .step-number {
	border-color: v-bind(primaryColorVar);
	background-color: v-bind(primaryColorVar);
}
</style>
