<template>
	<template v-if="options?.name && options?.name?.length > 0">
		<label
				:for="options?.slug"
				class="file-input-unit__label"
				:class="{'required': options?.required}"
		>
			{{ options?.name }}
		</label>
	</template>
	<div :class="{'file-input-unit__container': true, 'file-selected': isFileSelected}">
		<input
				type="file"
				:name="options?.slug"
				:id="options?.slug"
				:accept="acceptFormats"
				:multiple="options?.multiple ?? false"
				:required="options?.required ?? false"
				@change="fileChange($event)"
				:class="{
					'error': options?.errorState ?? false,
				}"

		/>
		<span class="file-input-unit__upload-message" :class="{'d-none': isFileSelected}">
			{{ options?.placeholder }}
		</span>
		<template v-if="options?.customUploadButton">
			<slot></slot>
		</template>
		<button-unit
				v-else
				@click-on-btn="triggerBrowsing"
				:options="{
					text: translate('browsing'),
					bgColor: primaryColorVar,
					textColor: buttonTextColor,
					shape: ButtonUnitShape.RECTANGLE,
					class: 'h-100',
				}"
		/>
	</div>

	<small
			v-show="options?.infoText && options?.infoText?.length > 0"
			:id="options?.slug+'_info'"
			class="mt-1 d-flex file-input-unit__label--secondary"
	>
		{{ options?.infoText }}
	</small>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {FileInputUnitOptions} from "./Interfaces/FileInputUnitOptions";
import ButtonUnit from "./ButtonUnit.vue";
import ProfilePageMixin from "../../Mixins/ProfilePageMixin";

export default defineComponent({
	name: "FileInputUnit",
	components: {ButtonUnit},
	emits: ['fileChange'],
	mixins: [ProfilePageMixin],
	props: {
		options: {
			type: Object as PropType<FileInputUnitOptions>,
			required: true,
		},
	},
	data() {
		return {
			acceptedFileFormats: ['webp', 'jpeg', 'jpg', 'png', 'gif', 'pdf', 'xls', 'xlsx', 'doc', 'docx'],
			isFileSelected: false,
		}
	},
	computed: {
		acceptFormats(): string {
			return this.fileFormatsConverter(this.options?.accept ?? this.acceptedFileFormats);
		},
	},
	methods: {
		fileFormatsConverter(arr: string[]): string {
			return arr.map(v => `.${v}`).toString();
		},
		fileChange(ev: any) {
			this.isFileSelected = !!ev.target.files?.length;
			this.$emit('fileChange', ev.target.files);
		},
		triggerBrowsing() {
			//@ts-ignore
			document.getElementById(this.options.slug).click();
		},
		reset(slug: string): void {
			//@ts-ignore
			document.getElementById(slug).value = '';
		}
	},
});
</script>

<style scoped>
</style>
