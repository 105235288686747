<template>
	<div v-if="siteInfo.integrations.facebook === true || siteInfo.integrations.google === true" class="flex-column">
		<button v-if="siteInfo.integrations.facebook === true" class="button--facebook-login button--lg mx-auto w-100 mb-2" type="button" @click="socialAction('fb')">
			<span><i class="fa-brands fa-facebook-f button--facebook-icon"></i></span>
			<span>{{ isApply ? this.$root?.translate('fb-apply-button') : this.$root?.translate('fb-login-button') }}</span>
		</button>
		<button v-if="siteInfo.integrations.google === true" class="button--google-login button--lg mx-auto w-100 mb-3" type="button" @click="socialAction('ggl')">
			<span><i class="fa-brands fa-google button--google-icon"></i></span>
			<span>{{ isApply ? this.$root?.translate('google-apply-button') : this.$root?.translate('google-login-button') }}</span>
		</button>
	</div>
</template>
<script lang="ts">
import {defineComponent} from "vue";
import Cookie from "js-cookie";
import {useSiteCheckStore} from "../../Stores/SiteCheck/SiteCheck";

export default defineComponent({
	name: "SocialActionComponent",
	setup() {
		return {
			siteStore: useSiteCheckStore()
		}
	},
	computed: {
		siteInfo(): any {
			return this.siteStore.site;
		}
	},
	props: {
		isApply: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		socialAction(driver: string): void {
			Cookie.set('actualUrl', window.location.origin + window.location.pathname, {expires: 30});
			window.location.href = driver === 'fb' ? '/auth/redirect/facebook' : '/auth/redirect/google';
		}
	}
});
</script>
<style scoped>
</style>
