import './bootstrap.js';
// @ts-ignore
import {createApp, h} from 'vue/dist/vue.esm-bundler';
import {createPinia} from 'pinia';

import App from "./Layouts/App.vue";
import router from "./router";
import {VueReCaptcha} from "vue-recaptcha-v3";
import mitt from "mitt";
import vSelect from "vue-select";
import Maska from 'maska'
import LocalizeMixin from "./Mixins/LocalizeMixin";
import {useLanguagesStore} from "./Stores/LanguagesStore";
import * as Sentry from "@sentry/vue";
import {ClickOutside} from "./Utils/ClickOutside";
import axios from "axios";
import {markRaw} from "vue";

const pinia = createPinia();
pinia.use(({store}) => {
	store.$router = markRaw(router)
});

const app = createApp({
	render: () => h(App),
	setup() {
		return {
			languagesStore: useLanguagesStore(),
		}
	},
	created() {
		axios.post('/api/config/captcha', {keys: ['google_recaptcha_site_key']}).then((response: any) => {
			if (response.status === 200 && response.data.success === true) {
				//@ts-ignore
				app.use(VueReCaptcha, {siteKey: response.data.items.google_recaptcha_site_key});
			}
		}).catch((error: any) => {
			console.log(error);
			console.log('google_recaptcha_site_key error');
		});
	},
	data() {
		return {}
	}
});
app.use(pinia);
app.use(router);
app.use(Maska)
app.mixin(LocalizeMixin);

export const emitter = mitt();
// @ts-ignore
const env = import.meta.env;

app.provide('emitter', emitter);
app.component('v-select', vSelect);
app.directive('click-outside', ClickOutside);

if (env.VITE_MIX !== 'staging' && env.VITE_MIX !== 'local') {
	Sentry.init({
		app,
		dsn: env.VITE_SENTRY_DSN_PUBLIC,
		integrations: [
			new Sentry.BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			}),
			new Sentry.Replay({
				maskAllText: false,
				blockAllMedia: false,
				maskAllInputs: false
			}),
		],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,

		// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
		// @ts-ignore
		tracePropagationTargets: ["localhost"],

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});

	app.mixin(Sentry.createTracingMixins({hooks: [], timeout: 0, trackComponents: true}));
	// @ts-ignore
	Sentry.attachErrorHandler(app, {attachProps: false, hooks: [], timeout: 0, trackComponents: undefined, logErrors: true});
}

app.mount("#app");
