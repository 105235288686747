import {defineStore} from 'pinia';
import axios from "axios";
import axiosClient from '../../API/axiosClient';
import Cookie from 'js-cookie';

import {AuthLoginInterface} from './Interfaces/AuthLoginInterface';
import {urls} from "./Utils/AuthUrls";
import {UserClass, UserInterface} from "../../Interfaces/UserInterface";
import {UserSDKClass, UserSDKInterface} from "../../Interfaces/UserSDKInterface";
import SweetAlert from "sweetalert2";
import {useRouter} from "vue-router";

export const useAuthStore = defineStore({
	id: 'AuthStore',
	state: () => ({
		loggedIn: !!Cookie.get('p_jwt_session'),
		user: {} as UserInterface,
		sdk: {} as UserSDKInterface,
		loaded: false,
		SDKLoadRequest: false,
	}),
	actions: {
		async loginRequest(email: string, password: string, token: string) {
			// Get laravel csrf token for sanctum login
			await getCSRF();
			// If i have csrf token in cookie then we login laravel
			const loginData: AuthLoginInterface = {email: email, password: password, reCaptchaToken: token};
			return await axios.post(urls.login, loginData);
		},
		async login(email: string, password: string, token: string) {
			return await this.loginRequest(email, password, token).then(({data}) => {
				if (data) {
					this.setToken(data.token);
					this.user = data.user;
				}
			});
		},
		async getMe() {
			return await axios.get(urls.getMe)
				.then(response => {
					if (response.status === 200 && response.data.user !== null) {
						this.user = response.data.user;
						this.loggedIn = true;
						this.loaded = true;
					}
				})
		},
		async getSDKData() {
			this.loaded = false;
			this.SDKLoadRequest = true;
			return await axios.get(urls.getSDK).then(response => {
				if (response.status === 200 && response.data.sdk !== null) {
					this.sdk = response.data.sdk;
					this.loaded = true;
				}
			}).catch((error: any) => {
				if (error.response.status === 404) {
					SweetAlert.fire({
						text: error.response.data.message,
						icon: 'error',
						confirmButtonText: 'OK'
					});
				} else {
					SweetAlert.fire({
						text: error.response.data.message,
						icon: 'error',
						confirmButtonText: 'OK'
					});
				}

				//@ts-ignore
				this.$router.push({name: 'home'});
				this.loaded = false;
				this.loggedIn = false;
				this.SDKLoadRequest = false;
				this.removeToken();
				this.sdk = new UserSDKClass();
				this.user = new UserClass();
			});
		},
		async logout() {
			return await axios.get(urls.logout)
				.then(response => {
					if (response.status === 200) {
						this.removeToken();
						this.user = new UserClass();
						this.loggedIn = false;
						this.loaded = false;
						setTimeout(() => {
							window.location.href = '/';
						}, 100);
					}
				}).catch((err) => console.error(err));
		},
		setToken(token: string): void {
			try {
				this.loggedIn = true;
				Cookie.set('p_jwt_session', btoa(token));
				axios.defaults.headers.common["Authorization"] = 'Bearer ' + atob(<string>Cookie.get('p_jwt_session'));
				axiosClient.defaults.headers.common["Authorization"] = 'Bearer ' + atob(<string>Cookie.get('p_jwt_session'));
			} catch (error) {
				// Ez van
			}
		},
		removeToken(): void {
			this.loggedIn = false;
			Cookie.remove('p_jwt_session');
			delete axios.defaults.headers.common["Authorization"];
		},

	},
	getters: {
		isLoggedIn: (state): boolean => state.loggedIn,
		currentUser: (state): UserInterface => state.user,
		currentSDK: (state): UserSDKInterface => state.sdk,
		isLoaded: (state): boolean => state.loaded,
		isSDKLoadRequest: (state): boolean => state.SDKLoadRequest

	}
})

export async function getCSRF() {
	return await axios.get(urls.getCSRFToken);
}

