import {defineComponent} from "vue";
import Sweetalert2 from "sweetalert2";

export default defineComponent({
	inject: ['emitter'],
	methods: {
		apply(indexId: number): void {
			// @ts-ignore
			this.emitter.emit('onModalShow_advert-apply-modal');
			// @ts-ignore
			this.emitter.emit('advertJobIndex', indexId);
		}
	}
});
