import {defineComponent} from "vue";
import {Breakpoint} from "../Utils/Breakpoint";

export default defineComponent({
	data() {
		return {
			isScreenUnderLg: false,
			isScreenUnderMd: false,
		}
	},
	mounted() {
		this.checkScreenWidth();
		window.addEventListener('resize', this.checkScreenWidth);
	},
	unmounted() {
		window.removeEventListener('resize', this.checkScreenWidth);
	},
	methods: {
		checkScreenWidth(): void {
			this.isScreenUnderLg = window.innerWidth < Breakpoint.LG;
			this.isScreenUnderMd = window.innerWidth < Breakpoint.MD;
		},
	},
});
