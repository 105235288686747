export interface UnionMemberDataInterface {
	beki_last_date: {
		from: boolean,
		to: boolean,
	},
	can_work: boolean,
	error: {}
}

export class UnionMemberDataClass implements UnionMemberDataInterface {
	beki_last_date = {
		from: false,
		to: false,
	};
	can_work = false;
	error = {};
}
