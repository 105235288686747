export interface ProfileOtherInterface {
	terms: string,
	terms_accepted_date: string,
	safety: string,
	safety_accepted_date: string,
	digital_data_form_done: boolean,
	digital_data_form_available: boolean,
}

export class ProfileOtherInterClass implements ProfileOtherInterface {
	terms = '';
	terms_accepted_date = '';
	safety = '';
	safety_accepted_date = '';
	digital_data_form_done = false;
	digital_data_form_available = false;
}
