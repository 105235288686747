<template>
	<label :for="this.options?.slug + '-birth_year'" class="birth-date-selector__label" :class="{'required': this.options?.required}">
		{{ this.options?.name }}
	</label>

	<div
			:class="{
				'row birth-date-selector__container': true,
				'error': this.options?.errorState,
			}"
			:id="this.options?.slug"
			:required="this.options?.required ?? false"
			type="birth-date"
	>
		<div class="col-4 pe-0">
			<select
					v-model="this.modelValue.year"
					class="birth-date-selector__select"
					:id="this.options?.slug + '-birth_year'"
					:name="this.options?.slug + '-birth_year'"
					:disabled="this.options?.readonly ?? false"
			>
				<option value="0" disabled selected hidden>
					{{ this.$root?.translate('year') }}
				</option>
				<option v-for="year in this.years" :value="year">
					{{ year }}
				</option>
			</select>
		</div>

		<div class="col-4 p-0">
			<select
					v-model="this.modelValue.month"
					class="birth-date-selector__select"
					:id="this.options?.slug + '-birth_month'"
					:name="this.options?.slug + '-birth_month'"
					:disabled="this.options?.readonly ?? false"
			>
				<option value="0" disabled selected hidden>
					{{ this.$root?.translate('month-2') }}
				</option>
				<option v-for="(month,index) in this.months" :value="index < 9 ? ('0' + (index+1).toString()) : (index+1)">
					{{ month }}
				</option>
			</select>
		</div>

		<div class="col-4 ps-0">
			<select
					v-model="this.modelValue.day"
					class="birth-date-selector__select"
					:id="this.options?.slug + '-birth_day'"
					:name="this.options?.slug + '-birth_day'"
					:disabled="this.options?.readonly ?? false"
			>
				<option value="0" disabled selected hidden>
					{{ this.$root?.translate('day-2') }}
				</option>
				<option v-for="day in this.days" :value="day">
					{{ day }}
				</option>
			</select>
		</div>
	</div>

	<small
			v-show="this.options?.infoText && this.options?.infoText?.length > 0"
			:id="this.options?.slug+'_info'"
			class="mt-1 d-flex birth-date-selector__label--secondary"
	>
		{{ this.options?.infoText }}
	</small>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {BirthDateSelectorOptions} from "./Interfaces/BirthDateSelectorOptions";
import MonthsMixin from "../../Mixins/MonthsMixin";
import {DateClass, DateInterface} from "../../Interfaces/DateInterface";

export default defineComponent({
	name: "BirthDateSelector",
	mixins: [MonthsMixin],
	props: {
		options: {
			type: Object as PropType<BirthDateSelectorOptions>,
			required: true,
		},
		modelValue: {
			type: Object as PropType<DateInterface>,
			required: true,
		},
	},
	data() {
		return {
			date: new DateClass(),
			years: [] as number[],
			days: [] as number[],
		}
	},
	mounted() {
		this.loadOptions();
	},
	methods: {
		loadOptions(): void {
			for (let day = 1; day <= 31; day++) {
				if(day > 0 && day < 10) {
					this.days.push(`0${day}`);
				} else {
					this.days.push(day);
				}
			}
			const d = new Date();
			for (let year = 1905; year < d.getFullYear(); year++) {
				this.years.push(year);
			}
		},
	}
});
</script>

<style scoped>
</style>
