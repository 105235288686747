<template>
    <v-modal :name="this.modalId" :header="translate('activation-modal-title')" class="activation__modal" :max-width="'600px'">
        <template #body>
            <form @submit.prevent="handleSubmit">
                <div class="row">
                    <div class="col-12">
                        <input-unit
                            v-model="email"
                            :options="{
                                slug: 'activation-email',
                                name: translate('email'),
                                placeholder: translate('email-placeholder'),
                                required: true,
                                disabled: urlParams.has('email'),
                                errorState: errors.hasOwnProperty('email'),
                                infoText: errors?.email?.[0] ?? '',
                            }"
                        />
                    </div>
                    <div class="col-12 my-4">
                        <email-verified-input
                            v-model="code"
                            :email="email"
                            :options="{
                                slug: 'activation-code',
                                name: this.$root?.translate('code'),
                                standardText: false,
                                placeholder: this.$root?.translate('please-enter-code'),
                                minlength: 6,
                                required: true,
                                errorState: errors.hasOwnProperty('code'),
                                infoText: errors?.code?.[0] ?? '',
                            }"
                        />
                    </div>
                    <div class="col-12">
                        <password-unit
                            v-model="password"
                            :options="{
                                slug: 'activation-password',
                                name: translate('password'),
                                placeholder: translate('min-8-char'),
                                minlength: 8,
                                required: true,
                                errorState: errors.hasOwnProperty('password'),
                                infoText: errors?.password?.[0] ?? '',
                            }"
                        />
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-4">
                    <button-unit
                        :options="{
                            text: translate('activation-modal-button'),
                            type: 'submit',
                            shape: ButtonUnitShape.ROUNDED,
                            bgColor: primaryColorVar,
                            textColor: buttonTextColor,
                            class: 'lg mb-4 mb-md-0 login-registration__primary-btn',
                            disabled: loading,
                            showLoader: loading,
                        }"
                    />
                </div>
            </form>
        </template>
    </v-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import axios from 'axios';
import VModalMixin from '../../Mixins/VModalMixin';
import VModal from '../VModal.vue';
import InputUnit from '../Units/InputUnit.vue';
import EmailVerifiedInput from '../Units/EmailVerifiedInput.vue';
import PasswordUnit from '../Units/PasswordUnit.vue';
import ProfilePageMixin from '../../Mixins/ProfilePageMixin';
import ButtonUnit from '../Units/ButtonUnit.vue';
import SweetAlertMixin from '../../Mixins/SweetAlertMixin';

export default defineComponent({
	name: "ActivationModal",
    mixins: [VModalMixin, ProfilePageMixin, SweetAlertMixin],
    inject: ['emitter'],
    components: {
        VModal,
        InputUnit,
        EmailVerifiedInput,
        PasswordUnit,
        ButtonUnit,
    },
    data() {
        return {
            modalId: 'activation-modal',
            urlParams: new URLSearchParams(window.location.search),
            loading: false,
            email: '',
            code: '',
            password: '',
            errors: {},
        }
    },
    mounted() {
        if(this.urlParams.has('email')) {
            this.email = this.urlParams.get('email');
        }
    },
    methods: {
        handleSubmit() {
            this.loading = true;
            axios.post('/api/users/activation', {email: this.email, code: this.code, password: this.password}).then(response => {
                if(response.data.success === true) {
                    this.successSaveAlert();
                    this.emitter.emit('onModalClose_activation-modal');
                } else {
                    this.errorSaveAlert();
                }
            }).catch(error => {
                if(error.response.status === 422) {
                    this.errors = error.response.data.errors;
                } else {
                    this.errorSaveAlert();
                }
            }).finally(() => this.loading = false);
            
        }
    }
});
</script>