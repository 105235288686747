import {ProfileStudentIdClass, ProfileStudentIdInterface} from "./ProfileStudentIdInterface";
import {ProfileCurrentStudyClass, ProfileCurrentStudyInterface} from "./ProfileCurrentStudyInterface";

export interface ProfileStudentInterface {
	isStudentCooperativeMember: boolean,
	can_work: boolean,
	student_id: ProfileStudentIdInterface,
	student_id_expiry: string,
	current_study: ProfileCurrentStudyInterface,
}

export class ProfileStudentClass implements ProfileStudentInterface {
	isStudentCooperativeMember = false;
	can_work = false;
	student_id = new ProfileStudentIdClass();
	student_id_expiry = '';
	current_study = new ProfileCurrentStudyClass();
}
