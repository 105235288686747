<template>
	<template v-if="this.item?.children && this.item?.children?.length">
		<p :class="{'layout-header-link': true, 'fw-bold': this.item.collapsed}" @click="this.item.collapsed = !this.item.collapsed" v-click-outside="close">
			<span class="layout-header-link__text" :title="this.item.title">{{ this.item.title }}</span>
			<template v-if="this.isScreenUnderLg">
				<i v-show="this.item.children?.length > 0 && this.item.collapsed" class="fa-solid fa-chevron-down ms-2"></i>
				<i v-show="this.item.children?.length > 0 && !this.item.collapsed" class="fa-solid fa-chevron-right ms-2"></i>
			</template>
			<template v-else>
				<i v-show="this.item.children?.length > 0 && this.item.collapsed" class="fa-solid fa-chevron-up ms-2"></i>
				<i v-show="this.item.children?.length > 0 && !this.item.collapsed" class="fa-solid fa-chevron-down ms-2"></i>
			</template>
		</p>

		<Transition name="expand">
			<ul v-if="this.item.children?.length > 0 && this.item.collapsed" class="layout-header-link__children">
				<li v-for="child in this.item.children" @click="this.$emit('clickOnChildren')">
					<a v-if="child.isExternalLink" :href="child.link" target="_blank" class="layout-header-link__child" :title="child.title">
						{{ child.title }}
					</a>
					<router-link v-else :to="{ path: child.link }" class="layout-header-link__child" :title="child.title">
						{{ child.title }}
					</router-link>
				</li>
			</ul>
		</Transition>
	</template>

	<v-link
			v-else
			:options="{
                title: this.item.title,
                link: this.item.link,
                isExternalLink: this.item.isExternalLink,
                class: {'layout-header-link': true, 'fw-bold': this.item.collapse}
            }"
	></v-link>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {LayoutHeaderLinkInterface} from "./Interfaces/LayoutHeaderLinkInterface";
import ScreenWidthChecker from "../../Mixins/ScreenWidthChecker";
import VLink from "../VLink.vue";
import {LayoutCssVariables} from "../../Utils/LayoutCssVariables";

export default defineComponent({
	name: "LayoutHeaderLink",
	components: {VLink},
	emits: ['clickOnChildren'],
	mixins: [ScreenWidthChecker],
	props: {
		item: {
			type: Object as PropType<LayoutHeaderLinkInterface>,
			required: true,
		},
	},
	computed: {
		bgColorVar(): string {
			return `var(--${LayoutCssVariables.header.bgColor})`;
		},
		textColor(): string {
			return `var(--${LayoutCssVariables.header.textColor})`;
		},
	},
	methods: {
		close() {
			this.item.collapsed = false;
		}
	}
});
</script>

<style scoped>
.layout-header-link__children {
	background-color: v-bind(bgColorVar);
}

.layout-header-link,
.layout-header-link__child {
	color: v-bind(textColor);
}
</style>
