import {GenderEnum} from "./GenderEnum";
import {DateClass, DateInterface} from "./DateInterface";
import {AddressClass, AddressInterface} from "./AddressInterface";
import {PlaceOfBirthClass, PlaceOfBirthInterface} from "./PlaceOfBirthInterface";
import {UnionMemberDataClass, UnionMemberDataInterface} from "./UnionMemberDataInterface";

export interface ProfilePersonalInterface {
	id: string,
	person_name: string,
	last_name: string,
	first_name: string,
	mother_name: string,
	email: string,
	birth_last_name: string,
	birth_first_name: string,
	gender: GenderEnum,
	nationality: string[],
	place_of_birth: PlaceOfBirthInterface,
	date_of_birth: DateInterface,
	telephone: string,
	permanent_address: AddressInterface,
	residency_address: AddressInterface,
	tax_id: string,
	social_sec_number: string,
	bank_account_number: string,
	union_member_data: UnionMemberDataInterface,
	doq_id: string,
	doq_id_valid: string,
}

export class ProfilePersonalClass implements ProfilePersonalInterface {
	id = '';
	person_name = '';
	last_name = '';
	first_name = '';
	mother_name = '';
	email = '';
	birth_last_name = '';
	birth_first_name = '';
	gender = GenderEnum.FEMALE;
	nationality = [''];
	place_of_birth = new PlaceOfBirthClass();
	date_of_birth = new DateClass();
	telephone = '';
	permanent_address = new AddressClass();
	residency_address = new AddressClass();
	tax_id = '';
	social_sec_number = '';
	bank_account_number = '';
	union_member_data = new UnionMemberDataClass();
	doq_id = '';
	doq_id_valid = '';
}
