import {IAdvertsResponse} from '../Views/AdvertListView/Interfaces/AdvertListItemInterface';
import axiosClient from './axiosClient';
import {ApplyAdvertResponse} from "../Interfaces/ApplyAdvertResponse";
import {ResponseSimple} from "../Utils/types";
import axios from "axios";

export async function getAdvertsByTerms(data: any, currentPage: number): Promise<void | IAdvertsResponse> {
	return axiosClient.post<IAdvertsResponse>(`/adverts?page=${currentPage}`, data)
		.then((response) => {
			if (response.status === 200) {
				return response.data;
			}
		});
}

export async function applyAdvert(advertId: number, email: string): Promise<void | ApplyAdvertResponse> {

	return axios.post(`/api/adverts/apply/${advertId}`, {email: email}).then((response: any) => {
		if (response.status === 200) {

			if (window.dataLayer && response.data.hasOwnProperty('dataLayer')) {
				window.dataLayer.push(JSON.stringify(response.data.dataLayer));
			}

			return response.data;
		}
	});
	// return axiosClient.post<ApplyAdvertResponse>(`/adverts/apply/${advertId}`, {email: email})
	// 	.then(response => {
	// 		if (response.status === 200) {
	//
	// 			if (window.dataLayer && response.data.hasOwnProperty('dataLayer')) {
	// 				window.dataLayer.push(JSON.stringify(response.data.dataLayer));
	// 			}
	//
	// 			return response.data;
	// 		}
	// 	});
}

export async function saveAdvert(advertId: number, isExistsDeleted: boolean = true): Promise<void | ResponseSimple<void>> {
	return axiosClient.post<ResponseSimple<{ success: boolean }>>(`/adverts/save/${advertId}`, {is_exists_deleted: isExistsDeleted})
		.then(response => {
			if (response.status === 200) {
				return response.data;
			}
		});
}

export default {
	getAdvertsByTerms,
	applyAdvert,
	saveAdvert
};
