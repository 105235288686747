import {defineComponent} from "vue";
import SweetAlert, {SweetAlertIcon} from "sweetalert2";

export default defineComponent({
	methods: {
		customAlert(title: string, text: string, icon: SweetAlertIcon): void {
			SweetAlert.fire({
				title,
				text,
				icon,
				confirmButtonColor: 'var(--alert-button)',
				confirmButtonText: this.$root?.translate('general-ok'),
			});
		},
		customSuccessAlert(title: string, text: string): void {
			this.customAlert(title, text, 'success');
		},
		customErrorAlert(title: string, text: string): void {
			this.customAlert(title, text, 'error');
		},
		customWarningAlert(title: string, text: string): void {
			this.customAlert(title, text, 'warning');
		},
		successSaveAlert(): void {
			this.customSuccessAlert(this.$root?.translate('success-save-title'), this.$root?.translate('success-save-text'));
		},
		errorSaveAlert(): void {
			this.customErrorAlert(this.$root?.translate('alert-error-title'), this.$root?.translate('alert-error-text'));
		},
		showAdvertLoadingAlert(): void {
			SweetAlert.fire({
				title: this.$root?.translate('general-loader-title'),
				html: this.$root?.translate('advert-loader-text'),
				allowOutsideClick: false,
				allowEscapeKey: false,
				didOpen: () => {
					SweetAlert.showLoading()
				},
			});
		}
	},
});
