import * as VueRouter from 'vue-router';

// Layout Pages
const PageView = () => import('./Views/PageView/PageView.vue');
const NewsListView = () => import('./Views/NewsListView/NewsListView.vue');
const NewsPageView = () => import('./Views/NewsPageView/NewsPageView.vue');
const AdvertListView = () => import('./Views/AdvertListView/AdvertListView.vue');
const AdvertPageView = () => import('./Views/AdvertPageView/AdvertPageView.vue');
const AdvertComingSoon = () => import('./Views/AdvertListView/AdvertComingSoon.vue');
/** Üdvözlő oldal */
const WelcomePage = () => import('./Views/ProfilePageModule/WelcomePage.vue');
/** Profilom */
const ProfilePage = () => import('./Views/ProfilePageModule/ProfilePage.vue');
/** Személyes adatok */
const PersonalPage = () => import('./Views/ProfilePageModule/PersonalPage.vue');
/** Értesítési beállításaim */
const NotificationsPage = () => import('./Views/ProfilePageModule/NotificationsPage.vue');
/** Jelszó módosítása */
const PasswordPage = () => import('./Views/ProfilePageModule/PasswordPage.vue');
/** Diák adatok */
const StudentDataPage = () => import('./Views/ProfilePageModule/StudentDataPage.vue');
/** Diákszövetkezet */
const StudentCooperativePage = () => import('./Views/ProfilePageModule/StudentCooperativePage.vue');
/** Végzettségek, munkatapasztalatok */
const EducationPage = () => import('./Views/ProfilePageModule/EducationPage.vue');
/** Önéletrajz */
const BiographyPage = () => import('./Views/ProfilePageModule/BiographyPage.vue');
/** Mentett ajánlataim */
const SavedAdvertsPage = () => import('./Views/ProfilePageModule/SavedAdvertsPage.vue');
/** Mentett keresések */
const SavedSearchesPage = () => import('./Views/ProfilePageModule/SavedSearchesPage.vue');
/** Ezekre jelentkeztem */
const SignedUpAdvertsPage = () => import('./Views/ProfilePageModule/SignedUpAdvertsPage.vue');
/** Dokumentumok */
const DocumentsPage = () => import('./Views/ProfilePageModule/DocumentsPage.vue');
/** Diák szövetkezeti szerződés */
const StudentAgreement = () => import('./Views/ProfilePageModule/StudentAgreement.vue');
/** Jelenléti ív */
const AttendancePage = () => import('./Views/ProfilePageModule/Attendance/AttendancePage.vue');
const AttendanceDashboardPage = () => import('./Views/ProfilePageModule/Attendance/AttendanceDashboardPage.vue');
const AttendanceCalendarPage = () => import('./Views/ProfilePageModule/Attendance/AttendanceCalendarPage.vue');
const AttendanceWorkedPage = () => import('./Views/ProfilePageModule/Attendance/AttendanceWorkedPage.vue');
/** 404 Nem található oldal */
const NotFoundPage = () => import('./Views/ErrorPagesView/NotFoundPage.vue');

const routes = [
	{
		path: '/',
		name: 'home',
		component: PageView,
	},
	{
		path: '/:permalink',
		name: 'page-view',
		alias: ['/landing/:permalink', '/page/:permalink'],
		component: PageView,
	},
	{
		path: '/hirek',
		alias: [
			'/news',
			'/vesti'
		],
		name: 'news',
		component: NewsListView,
	},
	{
		path: '/hirek/:slug',
		alias: ['/news/:slug'],
		name: 'news-detail',
		component: NewsPageView,
	},
	{
		path: '/profile',
		alias: [
			'/profil',  //sr
		],
		name: 'profile',
		component: ProfilePage,
		// meta: {auth: true},
		children: [
			{
				path: 'welcome',
				name: 'profile-welcome',
				alias: [
					'dobrodosli',  //sr
				],
				component: WelcomePage,
			},
			{
				path: 'personal',
				alias: [
					'licno',  //sr
				],
				name: 'profile-personal',
				component: PersonalPage,
			},
			{
				path: 'notifications',
				alias: [
					'obavestenja',  //sr
				],
				name: 'profile-notifications',
				component: NotificationsPage,
			},
			{
				path: 'password',
				alias: [
					'lozinka',  //sr
				],
				name: 'profile-password',
				component: PasswordPage,
			},
			{
				path: 'student-data',
				name: 'profile-student-data',
				component: StudentDataPage,
			},
			{
				path: 'student-agreement',
				name: 'student-agreement',
				component: StudentAgreement,
			},
			{
				path: 'student-cooperative',
				name: 'profile-student-cooperative',
				component: StudentCooperativePage,
			},
			{
				path: 'education',
				name: 'profile-education',
				component: EducationPage,
			},
			{
				path: 'biography',
				alias: [
					'biografija',  //sr
				],
				name: 'profile-biography',
				component: BiographyPage,
			},
			{
				path: 'saved-adverts',
				alias: [
					'sacuvani-oglasi', //sr
				],
				name: 'profile-saved-adverts',
				component: SavedAdvertsPage,
			},
			{
				path: 'saved-searches',
				alias: [
					'sacuvane-pretrage', //sr
				],
				name: 'profile-saved-searches',
				component: SavedSearchesPage,
			},
			{
				path: 'signed-up-adverts',
				alias: [
					'sacuvane-prijave', //sr
				],
				name: 'profile-signed-up-adverts',
				component: SignedUpAdvertsPage,
			},
			{
				path: 'documents',
				name: 'profile-documents',
				component: DocumentsPage,
			},
			{
				path: 'attendance',
				name: 'profile-attendance',
				component: AttendancePage,
				children: [
					{
						path: '',
						name: 'profile-attendance-index',
						component: AttendanceDashboardPage,
					},
					{
						path: 'workplace/:id',
						name: 'profile-attendance-workplace',
						component: AttendanceCalendarPage,
					},
					{
						path: 'worked/:date',
						name: 'profile-attendance-worked',
						component: AttendanceWorkedPage,
					},
				]
			}
		],
	},
	{
		path: '/karrier/:categories?/:locations?/:territorials?',
		alias: [
			'/adverts/:categories?/:locations?/:territorials?',
			'/poslova/:categories?/:locations?/:territorials?',
			'/poslovi/:categories?/:locations?/:territorials?',
			'/zaposlitev/:categories?/:locations?/:territorials?',
		],
		name: 'adverts',
		component: AdvertListView,
	},
	{
		path: '/allas/:slug/:advertid/:apply?',
		alias: [
			'/job/:slug/:advertid/:apply?',
			'/posao/:slug/:advertid/:apply?',
			'/zaposlitev/:slug/:advertid/:apply?',
		],
		name: 'advert-detail',
		component: AdvertPageView,
	}, {
		path: '/coming-soon',
		name: 'advert-coming-soon',
		component: AdvertComingSoon,
	},
	{
		path: '/:pathMatch(.*)*',
		name: '404',
		component: NotFoundPage,
	},
];

const router = VueRouter.createRouter({
	history: VueRouter.createWebHistory(),
	routes,
});

router.beforeEach(async (to, from, next) => {
	// const store = useAuthStore();
	//
	// if ((to.name !== 'login' && to.name !== 'register') && !store.isLoggedIn) {
	// 	next({name: "login"});
	// } else if ((to.name === 'login' || to.name === 'register') && store.isLoggedIn) {
	// 	next({name: 'dashboard'});
	// } else if (store.isLoggedIn === false && to.meta.auth === true) {
	// 	await Sweetalert2.fire({
	// 		title: 'Kérlek jelentkezz be az oldal megtekintéséhez!',
	// 		icon: 'warning',
	// 		confirmButtonColor: 'var(--colorMain)',
	// 		confirmButtonText: 'OK'
	// 	});
	// 	await router.push({name: 'home'});
	// 	return;
	// }
	// // Otherwise allowed viewing..
	// if (store.isLoggedIn && !store.isLoaded) {
	// 	await store.getMe();
	// }
	next();
});

export default router;
